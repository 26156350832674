<div *ngIf="loading" [ngClass]="{'content-loading zindex':loading,'content-loaded':!loading}">
    <mat-spinner diameter="25" strokeWidth="5"></mat-spinner>
</div>
<div class="add-link-dialog" *ngIf="action =='Add' || action== 'Edit'">
    <div mat-dialog-title>
        <span class="text-px-16 font-sans-hairline font-sans">{{action}} Link </span>
    </div>
    <mat-dialog-content>
        <form [formGroup]="formGroup">
            <div class="font-sans fix-mat-form-field">
                <mat-form-field appearance="fill" class="add-link-dialog text-px-16 w-full">
                    <mat-label>Name</mat-label>
                    <input matInput maxLength="50" formControlName="linkName" placeholder="Name" required>
                    <mat-hint class="pb-4 font-bold" *ngIf="formGroup.get('linkName').value && formGroup.get('linkName').value.length>=18">
                        <span>Note that only the first 18 characters will show in the smallest tile size.</span>
                    </mat-hint>
                </mat-form-field>
                <br>
                <mat-form-field appearance="fill" class="text-px-16 w-full">
                    <mat-label>Address</mat-label>
                    <input matInput formControlName="linkUrl" placeholder="Address" required>
                </mat-form-field>
            </div>
        </form>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <div class="flex font-sans">
            <button mat-button color="accent" class="text-px-14 uppercase" (click)="closeDialog()"><span
                   >cancel</span></button>
            <button *ngIf="action=='Add'" mat-button color="accent" class="text-px-14 uppercase" (click)="doAction()"
                [disabled]="!formGroup.valid" [analyticTagging]="{'eventId':'addLinkWidget','srcCtrl':this,'obj':formGroup.get('linkName').value}"><span>Add</span></button>
            <button *ngIf="action=='Edit'" mat-button color="accent" class="text-px-14 uppercase" (click)="EditAction()"
                [disabled]="!formGroup.valid"><span>Update</span></button>
        </div>
    </mat-dialog-actions>
</div>
