import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { AppListingService } from 'src/app/app-listing/services/app-listing.service';
import { RoleSurveyService } from 'src/app/user-role-survey/role-survey/role-survey.service';
import { UserApiService } from 'src/app/user/user-api.service';
import { WorkspaceService } from 'src/app/workspace/workspace.service';
import { YourAccountService } from '../your-account/your-account.service';
import { AppConfigService } from 'src/app/core/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class StartUpService {

  constructor(
    private workspaceService: WorkspaceService, 
    private appListingService: AppListingService,
    private yourAccountService: YourAccountService,
    private roleSurveyService: RoleSurveyService,
    private userApiService: UserApiService,
    private appConfigService: AppConfigService) {
    this.startUpService();
    if (window.location.href.includes('/Platform/workspace/home')
      || window.location.href.includes('/Platform/workspace/')){
        sessionStorage.setItem('pageLoadSts', 'true');
    } else {
      sessionStorage.setItem('pageLoadSts', 'false');
    }

  }
  startUpService = () => {
    // Check Query Parameter Routing
    this.processQueryParamRouting();

    if (window.location.href.includes('/Platform/apps')){
      this.appListingService.getAppList([], true).subscribe((response) => {
        this.workspaceAPI();
       });
    }
    else if (window.location.href.includes('/Solo')) {
    }
    else {
      this.workspaceAPI();
      this.appListingService.getAppList([], true).subscribe((response) => { });
    }
    this.yourAccountService.getUserPreferences().subscribe((response) => { });
    this.roleSurveyService.getJobRoleSurveyData(true).subscribe((response) => { });
    this.userApiService.getEAFList().subscribe((response) => { });
    
    // When the user data refershes, check if the user data sources were updated
    // If so, reload the user's workspace and app listing
    this.workspaceService.userRefreshed.subscribe((value) => {
      if (value && this.userApiService.isNewSourceListed()) {
        this.workspaceService.getDefaultWorkspace(false, true, true).subscribe((workspace) => {
          // Also updated the cached MBF Home if this workspace matches
          if (workspace && workspace.data && workspace.data.longName === 'MBF Home'){
            this.workspaceService.setHomeWorkspace(of(workspace));
          } 
        });
        this.appListingService.getAppList([], true, true).subscribe((response) => { });
      }
    });
  }

  workspaceAPI(){
    this.workspaceService.getDefaultWorkspace(true, true, false).subscribe((workspace: any) => {
      if (workspace && workspace.data && workspace.data.longName === 'MBF Home'){
        this.workspaceService.setHomeWorkspace(of(workspace));
      } else {
        this.workspaceService.getHomeWorkspace(true, true, window.location.href.endsWith('/Platform/workspace/home'));
      }
    }, (error: any) => {
      this.workspaceService.getHomeWorkspace(true, true, window.location.href.endsWith('/Platform/workspace/home'));
    });
  }

  processQueryParamRouting() {
    const targetPage = this.getQueryParam("page");
    if(targetPage != null) {
        const queryParamMap = this.appConfigService.getRoute('queryParamMap');
        if(queryParamMap != null && queryParamMap[targetPage] != null) {
          const route = queryParamMap[targetPage].path;
          if(route != null) {
            const appId = this.getQueryParam("app");
            if(queryParamMap[targetPage].isComplex && appId != null) {
              window.location.href = route + appId;
            } else {
              window.location.href = route;
            }
          }
        }
    } 
  }

  getQueryParam(paramName:any): any {
    var results = new RegExp('[\?&]' + paramName + '=([^&#]*)').exec(window.location.href);
    if (results == null){ return null; }
    else { return decodeURI(results[1]) || 0; }
  }
}
