import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/core/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  private fedProxySamlUrl: string = "https://auth.boeingservices.com/idp/startSLO.ping";
  private idpUrl: string = ".aviationid.com/credmgr/accesslogin/Logout.faces";
  private fedProxyPingAccessUrl: string = "pa/oidc/logout";
  private portalUrl = "/portal_ng/logout";
  private timeout: any = 5000;

  constructor(
    private appConfigService: AppConfigService
  ) {
    // Get the Logout URLs from Env Config
    this.appConfigService.envConfig.subscribe(config => {
      if (config['appLogout']) {
        this.fedProxyPingAccessUrl = config['appLogout'];
      }
      if (config['logoutFedProxySaml']) {
        this.fedProxySamlUrl = config['logoutFedProxySaml'];
      }
      if (config['logoutUrl']) {
        this.idpUrl = config['logoutUrl'];
      }
    });

    // Set the Timeout time
    this.timeout = this.appConfigService.getProperty('logOutTimeout');
    this.timeout = this.timeout ? this.timeout : 5000;

    // Set the Portal Application Logout URL
    this.portalUrl = this.appConfigService.getBackendPath() + "/logout";
    this.portalUrl = this.portalUrl ? this.portalUrl : "/portal_ng/logout";
  }

  logout(clearStorage: boolean) {
    if(clearStorage) {
      this.clearStorage();
    }

    this.chainLogouts();
  }

  private clearStorage() {
    localStorage.removeItem('welcomeMsgAndNotificationDisplayed');
    sessionStorage.removeItem('ccid');
    sessionStorage.removeItem('sentToEOL');
    localStorage.removeItem('MBFSelectedBrowser');
    sessionStorage.removeItem('reloadPageBrowserType');
    sessionStorage.removeItem('breadCrumbList');
    window.sessionStorage.clear();
    localStorage.clear();
  }

  private buildIdpFpLogoutUrl(): string {
    return this.idpUrl + "?returnUrl=" + this.fedProxySamlUrl + "%3FTargetResource%3D" + encodeURIComponent(window.location.origin) + '?t=' + new Date().getTime();
  }

  private idpFedProxyLogout() {
    let idpFedProxyUrl = this.buildIdpFpLogoutUrl();
    var xhttpPA = new XMLHttpRequest();
    xhttpPA.onreadystatechange = () => {
      if (xhttpPA.readyState == 4 && xhttpPA.status == 200) {
        setTimeout(this.logoutRedirect, this.timeout, idpFedProxyUrl);
      }
    };

    xhttpPA.open("GET", this.fedProxyPingAccessUrl, true);
    xhttpPA.send();
  }

  private portalLogout() {
    var xhttpPortal = new XMLHttpRequest();
    xhttpPortal.open("GET", this.portalUrl, true);
    xhttpPortal.send();
  }

  private chainLogouts() {
    this.idpFedProxyLogout();
    this.portalLogout();
  }

  private logoutRedirect(logoutPageUrl: string) {
    window.location.href = logoutPageUrl;
  }
}