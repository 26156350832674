import { Injectable } from '@angular/core';
import { Observable, EMPTY, throwError, BehaviorSubject } from 'rxjs';
import { AppConfigService } from 'src/app/core/app-config.service';
import { HeaderData } from 'src/app/header/header-data';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  openSideMenuValue = new BehaviorSubject<boolean>(false);
  openSideMenuObservable = this.openSideMenuValue.asObservable();
  workspaceLockSts = new BehaviorSubject<boolean>(false);
  workspaceLockStsObservable = this.workspaceLockSts.asObservable();
  openNotificationData = new BehaviorSubject<boolean>(false);
  openNotificationDataObservable = this.openNotificationData.asObservable();
  viewSearchData = new BehaviorSubject<boolean>(false);
  viewSearchDataObservable = this.viewSearchData.asObservable();
  undismissedNotificationsAvailable = new BehaviorSubject<boolean>(false);
  undismissedNotificationsAvailableObservable = this.undismissedNotificationsAvailable.asObservable();
  notificationMarkedRead = new BehaviorSubject<boolean>(false);
  notificationMarkedReadObservable = this.notificationMarkedRead.asObservable();
  notificationsUpdated = new BehaviorSubject<boolean>(false);
  notificationsUpdatedObservable = this.notificationsUpdated.asObservable();
  learnMoreClicked = new BehaviorSubject<boolean>(false);
  learnMoreClickedObservable = this.learnMoreClicked.asObservable();
  dismissClicked = new BehaviorSubject<boolean>(false);
  dismissClickedObservable = this.dismissClicked.asObservable();
  logoutClicked = new BehaviorSubject<boolean>(false);
  logoutClickedObservable = this.logoutClicked.asObservable();
  private readonly cacheApi: Map<string, Observable<string[]>> =
    new Map<string, Observable<string[]>>();

  constructor(private httpClient: HttpClient, private appConfigService: AppConfigService) { }

  setopenSideMenu(value: boolean): void {
    this.openSideMenuValue.next(value);
  }

  setWorkspaceLockSts(value: boolean): void {
    this.workspaceLockSts.next(value);
  }

  setopenNotificationData(value: boolean): void {
    this.openNotificationData.next(value);
  }

  setViewSearchData(value: boolean): void {
    this.viewSearchData.next(value);
  }

  setUndismissedNotificationsAvailable(value: boolean): void {
    this.undismissedNotificationsAvailable.next(value);
  }

  setNotificationMarkedRead(value: boolean): void {
    this.notificationMarkedRead.next(value);
  }

  setNotificationsUpdated(value: boolean): void {
    this.notificationsUpdated.next(value);
  }

  setLearnMoreClicked(value: boolean): void {
    this.learnMoreClicked.next(value);
  }

  setDismissClicked(value: boolean): void {
    this.dismissClicked.next(value);
  }

  setLogoutClicked(value: boolean): void {
    this.logoutClicked.next(value);
  }
  
  public getHeaderData(oemPlatformAcronym: string): Observable<HeaderData> {
    return (oemPlatformAcronym !== undefined)
      ? this.appConfigService.fetchComponentConfig(`header-data-${oemPlatformAcronym.toLowerCase()}`)
      : EMPTY;
  }

  public getSearchResult(searchData): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const endpoint = this.appConfigService.getBackendPath('headerSearchEndpoint');
    return this.httpClient.post<any>(endpoint, JSON.stringify(searchData), httpOptions);
  }

  public getActiveNotifications(withCache = true): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('notificationEndpoint') + '/active';
    if (withCache) {
      if (!this.cacheApi[endpoint]) {
        this.cacheApi[endpoint] = this.httpClient.get<any>(endpoint).pipe(shareReplay(), catchError(this.error));
      }
    } else {
      this.cacheApi[endpoint] = this.httpClient.get<any>(endpoint).pipe(shareReplay(), catchError(this.error));
    }
    return this.cacheApi[endpoint];
  }
  public deleteNotification(messageId: string): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('singleSystemMessageEndpoint') + '/' + messageId + '/' + 'delete';
    return this.httpClient.get<any>(endpoint, { observe: 'response' }).pipe(catchError(this.error));
  }

  public markAsReadNotification(messageId: string): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('singleSystemMessageEndpoint') + '/' + messageId + '/viewed';
    return this.httpClient.get<any>(endpoint, { observe: 'response' }).
      pipe(catchError((error: HttpErrorResponse) => this.returnTrueForViewError(error)));
  }

  // Clear all the unread Notification for the logged in User
  public clearUpdates(messageIds: string[]): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('singleSystemMessageEndpoint') + '/markAllAsRead';
    return this.httpClient.post<any>(endpoint, messageIds).pipe(catchError(this.error));
  }

  // Delete all the User Notification for the logged in User - Clear All Updates
  public clearAllUpdates(): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('singleSystemMessageEndpoint') + '/delete';
    return this.httpClient.get<any>(endpoint, { observe: 'response' }).pipe(catchError(this.error));
  }

  // Dismiss notification
  public dismissNotification(messageId: string): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('singleSystemMessageEndpoint') + '/' + messageId + '/dismissed';
    return this.httpClient.get<any>(endpoint, { observe: 'response' }).pipe(catchError(this.error));
  }

  // Handle error
  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  returnTrueForViewError(error: HttpErrorResponse) {
    if (error.status === 200 || error.status === 500) {
      return 'S';
    }
  }

  /**
   * return unalter string if:
   * - full url (starts with http
   * - fully qualified url (starts with /)
   * - routes are assumed to be routes in portal_ng, so get backend path
   * @param url input URL
   * @param type URL type
   * @param envConfig env config data
   */
  buildUrlFromString(url: string = '', type: string, envConfig) {
    if (url === '') {
      return url;
    }
    let updatedUrl: string;
    switch (type) {
      case 'portal_ng':
        updatedUrl = `${this.appConfigService.getPortalNgPath()}/${url}`;
        break;
      case 'rp':
        if (url.startsWith('/')) {
          updatedUrl = url;
        }
        // tslint:disable-next-line: no-string-literal
        else if (envConfig['isThroughRp']) {
          // tslint:disable-next-line: no-string-literal
          updatedUrl = `${envConfig['rpAlias']}${url}`;
        }
        else {
          updatedUrl = `/${url}`;
        }
        break;
      case 'external':
      default:
        updatedUrl = url;
        break;
    }
    return updatedUrl;
  }
}
