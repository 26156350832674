import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUtilityService } from 'src/app/services/shared/common-utility.service';
import { SystemMessage } from '../../system-message-create-edit-view/system-message-interface';

@Component({
  selector: 'app-system-message-dialog-box',
  templateUrl: './system-message-dialog-box.component.html',
  styleUrls: ['./system-message-dialog-box.component.css']
})
export class SystemMessageDialogBoxComponent implements OnInit {
  action: string;
  dialogData: SystemMessage;

  constructor(
    private commonUtilityService: CommonUtilityService,
    public dialogRef: MatDialogRef<SystemMessageDialogBoxComponent>,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.action = data.action;
    this.dialogData = { ...data };
  }

  ngOnInit(): void {
    this.commonUtilityService.resizeIframe();
  }

  doAction() {
    this.dialogRef.close({ event: this.action, dialogData: this.dialogData });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  /**
   * Method to get the Headers that should be displayed inside Notification drop down list
   * @param notificationDetails Notification details
   */
  getNotificationsHeader(messageType: string): string {
    if (messageType === 'UNPLANNED_OUTAGE') {
      return 'UNPLANNED OUTAGE';
    } else if (messageType === 'PLANNED_OUTAGE') {
      return 'PLANNED OUTAGE';
    } else if (messageType === 'USER_NOTIFICATION' || messageType === 'INFORMATIONAL') {
      return 'UPDATE';
    }
  }

}
