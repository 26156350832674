<div *ngIf="loading" [ngClass]="{'content-loading zindex':loading,'content-loaded':!loading}">
    <mat-spinner diameter="25" strokeWidth="5"></mat-spinner>
</div>
<div class="folder-dialog-width">
    <div mat-dialog-title>
        <span class="text-px-18 font-sans font-normal leading-5">Archived Folders </span>
    </div>
    <mat-dialog-content>
        <div *ngIf="dialogLoading" class="heightForSpinner">
            <mat-spinner style="margin:0 auto;" diameter="25" strokeWidth="5"></mat-spinner>
        </div>
        <div *ngIf="archivedWidgets && archivedWidgets.length > 0">
            <span class="font-sans font-normal text-px-14">Select to add an archived folder back to My Favorites. </span>
            <div class="my-8">
                <form [formGroup]="form">
                    <div class="my-2" *ngFor="let widget of archivedWidgets">
                        <mat-checkbox (change)="onChangeEventFunc(widget, $event)">
                            <span class="font-sans font-normal text-px-14"> {{ widget.longName }} </span>
                        </mat-checkbox>
                    </div>
                </form>
            </div>
        </div>
        <div *ngIf="archivedWidgets && archivedWidgets.length == 0">
            <span class="font-sans font-normal text-px-14"> No archived folders exist. </span>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
            <button tabindex="-1" mat-button color="accent" class="text-px-14 uppercase font-semibold" (click)="closeDialog()">Cancel</button>
            <button tabindex="-1" mat-button color="accent" class="text-px-14 uppercase font-semibold" [disabled]="!form.valid" (click)="restoreWidget()" [analyticTagging]="{'eventId':'retrieveArchive','srcCtrl':this}">Add</button>
    </mat-dialog-actions>
</div>
