<div class="eol-content eol-width">
    <div class="eol-header">
        <div class="eol-header-title  font-semibold">
            Internet Explorer Support Has Now Ended
        </div>
    </div>
    <div class="eol-body">
        <div>
            <div class="eol-body-content eol-body-font">
                Microsoft has <a class="cursor-pointer primary-color" target="_blank" href="https://blogs.windows.com/windowsexperience/2021/05/19/the-future-of-internet-explorer-on-windows-10-is-in-microsoft-edge/">announced</a> they will be discontinuing
                IE11 as a browser application on June 15, 2022. Based on that decision, <b> MyBoeingFleet has ended support of the IE11 browser.</b> We will no longer test applications on IE11, and therefore cannot guarantee application performance or
                behavior. In the coming weeks, some key features may not be available or will not perform properly using IE11. This may also occur if Edge IE mode is not set properly (please refer to MBF FAQ instructions for setting Edge properly).
            </div>
            <div class="eol-body-content-list">
                <ul class="list-style-disc">
                    <li class="pt-5">
                        <b>Please migrate now </b> to one of the other
                        <a class="cursor-pointer primary-color" (click)="downloadFile(ieMbfSupportedBrowsersUrl)">browsers supported by MBF.</a>
                    </li>
                    <li class="pt-5 pb-5">
                        As noted in the
                        <a class="cursor-pointer primary-color" (click)="downloadFile(ieMbfBrowserOverviewUrl)">overview</a>, some of the more popular applications on MBF will require Edge (set to IE mode) to function properly. This includes V1 Toolbox,
                        V2 Toolbox, and Product Standards.
                    </li>
                    <li class="pb-5">
                        You can find information about hardware & software requirements under the FAQs on the <a class="cursor-pointer secondary" target="_blank" href="{{pspUrl}}">MyBoeingFleet
                            Support</a> page.
                    </li>

                </ul>
            </div>
            <div class="eol-body-content-list-padding">
                <div class="eol-header-title  font-semibold pb-5">
                    What Do I Need to Do?
                </div>
                <div class="eol-body-content eol-body-font pb-5">
                    <span>For details on how to proceed, select the browser you are currently
                        using.</span>
                </div>
            </div>
            <div>
                <div [ngClass]="{'align-radio-group':(selectedBrowser !=='Edge' && selectedBrowser !=='IE' ) }">
                    <mat-radio-group color="primary" class="flex flex-col pb-5 radio-content" (change)="browserTypeConfirmtion($event)">
                        <div id="ie11" [ngClass]="{ 'eol-radio-border': selectedBrowser==='IE' , 'eol-radio-no-border mb-6': (selectedBrowser !=='Edge' && selectedBrowser !=='IE' )} ">
                            <div class="flex-radio-button-content">
                                <div class="align-radio-content-ie11" [ngClass]="{ 'align-when-selected  ': selectedBrowser==='Edge' }">
                                    <mat-radio-button value="IE">

                                        <span class="eol-body-content eol-body-font font-medium">I’m using IE11</span>

                                    </mat-radio-button>
                                </div>
                                <div>
                                    <img src="assets/img/browser-eol/ie11-logo.png " class="logo ">
                                </div>
                            </div>
                            <div *ngIf="selectedBrowser==='IE' " class="eol-body-content eol-body-font pt-5 ">
                                <div class="eol-body-content-list">

                                    <span class="pb-5">Please migrate to one of the other <a
                                            class="cursor-pointer primary-color"
                                            (click)="downloadFile(ieMbfSupportedBrowsersUrl)">browsers supported by
                                            MBF.</a></span>
                                </div>
                            </div>
                        </div>
                        <div class="edge mb-6" [ngClass]="{ 'eol-radio-border mt-4': selectedBrowser==='Edge' , 'eol-radio-no-border mt-0': (selectedBrowser !=='Edge' && selectedBrowser !=='IE' )} ">
                            <div class="flex-radio-button-content">
                                <div class="align-radio-content-edge" [ngClass]="{ 'align-when-selected  ': selectedBrowser==='IE' }">
                                    <mat-radio-button value="Edge">

                                        <span class="eol-body-content eol-body-font font-medium">I’m using Edge</span>

                                    </mat-radio-button>
                                </div>
                                <div class="pt-2">

                                    <img src="assets/img/browser-eol/edge-logo.png " class="logo ">

                                </div>
                            </div>
                            <div *ngIf="selectedBrowser==='Edge' " class="eol-body-content eol-body-font pt-5 ">
                                <div class="eol-body-content-list">

                                    <span>When visiting most applications (including the MBF home page) </span>
                                    <span class="font-bold">the correct setting for IE mode is TURNED OFF.</span>
                                </div>
                                <div class="eol-body-content-list pt-6 ">
                                    <span>However, when visiting the following, </span>
                                    <span class="font-bold">IE mode should be TURNED ON:</span>
                                </div>
                                <div class="eol-body-content-list pt-3">
                                    <span>V1 Toolbox | V2 Toolbox | Product Standards</span>
                                </div>
                                <div class="flex flex-row pt-8 eol-body-content-list">
                                    <div><img class="edge-warning-height" src="assets/img/browser-eol/browser-eol-edge-warning-image.jpg " alt="Select to Turn Off IE Mode "></div>
                                    <div class="edge-message ">
                                        <p><b>To manually turn IE mode off/on,</b> select the icon in the upper right corner of your browser.</p>
                                        <p class="pt-6 ">If this icon is not present or you cannot select it, please contact your Electronic Access Focal (EAF) or IT browser administrator to correct your browser’s IE mode settings for MyBoeingFleet.
                                            <a class="cursor-pointer primary-color" (click)="downloadFile(edgeTurnOffIEModeUrl)">This overview </a> provides information on how to fix this issue.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
            <div *ngIf="selectedBrowser ==='Edge' || selectedBrowser ==='IE'" class="eol-body-content pb-5 pt-5 ">
                If further assistance is needed, please
                <a class="cursor-pointer primary-color " (click)="getURL( 'csp-ui', '_blank', 'rp', 'Contact Us') ">Contact Us.</a>
            </div>
        </div>
        <div class="eol-footer pt-4">
            <div class="eol-footer-continue-button pb-4">
                <button mat-button [disabled]="disableContinue " [ngClass]="{ 'eol-footer-enable-continue-button': !disableContinue, 'eol-footer-disable-continue-button': disableContinue} " [analyticTagging]="{ 'eventId': 'browserIdentificationContinue',
                                    'srcCtrl': 'MBF: BrowserIdentification: Continue, optionSelected: '+ this.adobeSelection} " (click)="submitContinue() ">CONTINUE</button>
            </div>
        </div>
    </div>
</div>