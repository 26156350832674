import { Injectable } from '@angular/core';
import { EventCatelogService } from 'src/app/admin/event-catelog-admin/event-catelog-service.service';
import { AppConfigService } from 'src/app/core/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class EventCatalogSharedService {

  private eventActionMapping: any;

  constructor(private eventCatelogService: EventCatelogService, private appConfigService: AppConfigService) {
    this.appConfigService.fetchComponentConfig('event-catalog-action-mapping').subscribe(results => {
      this.eventActionMapping = results.eventActionMapping;
    });
  }

  public eventCatalogTracking(evName: any, evAction: any, evTabAction?: any, evWidgetId?: any) {
    const eventActionData = {
      eventName: evName,
      eventData: this.getEventAction(evAction, evTabAction),
      widgetId: (evWidgetId === undefined || evWidgetId === '') ? null : evWidgetId
    };
    this.eventCatelogService.addEventData(eventActionData).subscribe(result => {
    });
  }

  private getEventAction(evAction: any, evTabAction?: any) {
    const eventAction = this.eventActionMapping.filter((obj) => {
      return obj.eventId === evAction;
    });
    return eventAction[0].eventAction !== 'TabSelection' ? eventAction[0].eventAction : evTabAction;
  }
}
