import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCamelCase'
})
export class CustomCamelCasePipe implements PipeTransform {

  transform(title: string): string {
    const str = title.split(' ');
    let camelCaseTitle = '';
    let word = '';
    for ( let i = 0; i < str.length; i++) {
      if (str[i] === 'MBF' || str[i] === 'APIs') {
        word = str[i];
      }
      else {
        word = str[i].substring(0, 1) + str[i].substring(1).toLowerCase();
      }
      if (i === 0) {
        camelCaseTitle = camelCaseTitle + word;
      }
      else {
        camelCaseTitle = camelCaseTitle + ' ' + word;
      }
    }
    return camelCaseTitle;
  }

}
