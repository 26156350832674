import { Component, Inject, NgZone, OnInit, Optional, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConfigService } from 'src/app/core/app-config.service';
import tutorialData from '../../../assets/config/data/tutorial/workspace-tutorial-MBF.json';

@Component({
  selector: 'app-tutorial-dialog',
  templateUrl: './tutorial-dialog.component.html',
  styleUrls: ['./tutorial-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TutorialDialogComponent implements OnInit {

  action = '';
  dialogData: any = [];
  isLinear = false;
  tutorialSlides: any = [];
  currentIndex = 0;
  layoutName = 'welcome';
  tHiLight: any;
  tHiTarget: any;
  plateformName = '';
  currentSlide: any;
  closeSts = false;
  loaded = false;
  fullBodyHgt = 0;
  img = [''];
  constructor(public dialogRef: MatDialogRef<TutorialDialogComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              private cd: ChangeDetectorRef,
              private snackBar: MatSnackBar, private appConfigService: AppConfigService, private ngZone: NgZone) {
              this.action = data.action;
              this.dialogData = data.data;
              dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.appConfigService.envConfig.subscribe(config => {
      this.plateformName = config[`oemPlatformName`];
    });
    this.img[3] = 'assets/img/tutorial/MBF/your-mbf-home-page.gif';
    this.img[4] = 'assets/img/tutorial/MBF/customize-my-favorites.gif';
    this.img[5] = 'assets/img/tutorial/MBF/manage-folders.gif';
    this.currentIndex = 0;
    this.tutorialSlides = tutorialData;

    this.tHiLight = document.getElementById('tutorial-highlight');
    this.tHiLight.style.display = 'block';
    this.tHiTarget = document.getElementById('tutorial-highlight-target');
    this.tHiTarget.style.height = '0px';
    this.tHiTarget.style.width = '0px';
    this.snackBar.dismiss();
    document.getElementById('htmlContent').style.overflow = 'hidden';

    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('resize', this.onResize.bind(this));
    });
    this.closeSts = true;
    this.fullBodyHgt = document.getElementById('fullBodyContent').offsetHeight;
    this.cd.markForCheck();
  }

  next(){
    const cnt = document.getElementById('tutorial-dlg');
    if (this.currentIndex < this.tutorialSlides.length) {
      this.currentIndex += 1;
      this.highlightingElement(this.tutorialSlides[this.currentIndex], cnt);
      this.currentSlide = this.tutorialSlides[this.currentIndex];
      }
    this.layoutName = this.tutorialSlides[this.currentIndex].mediaPresent ? 'contentWithMedia' : 'content';
  }

  highlightingElement(item, tmodel) {
    this.ngZone.runOutsideAngular(() => {
      const hElement = document.getElementById('tutorial-highlight');
      const hElementTarget = document.getElementById('tutorial-highlight-target');
      if (window.innerWidth > 991){
        if (item && item.highlight && document.getElementById(item.highlight)){
        const marker = document.getElementById(item.highlight).getBoundingClientRect();
        if (!(item.index === 4 || item.index === 6)){
          window.scrollTo({top: 0, behavior: 'smooth'});
          hElement.style.display = 'block';
          hElementTarget.style.top = (marker.top) + 'px';
          hElementTarget.style.left = (marker.left) + 'px';
          hElementTarget.style.height = (marker.height) + 'px';
          hElementTarget.style.width = (marker.width) + 'px';
          if (item.position === 'left'){
            tmodel.style.left = (marker.left - 5) + 'px';
            tmodel.style.top = (marker.top + marker.height + 24) + 'px';
          } else if (item.position === 'right'){
            tmodel.style.left = 'calc(' + (marker.right + 5) + 'px - 360px)';
            tmodel.style.top = (marker.top + marker.height + 24) + 'px';
          }
        } else {
          let adjustedHeight = 0;
          if (this.fullBodyHgt - marker.top <= 607){
            adjustedHeight = 607 - (this.fullBodyHgt - marker.top);
          }
          const browser = window.navigator.userAgent;
          const isIE = browser.indexOf('MSIE ') > -1 || browser.indexOf('Trident/') > -1;
          // tslint:disable-next-line: radix
          const nb = parseInt((marker.top + '').replace('px', '')) - 50;
          if (isIE){
            window.scrollTo(0, nb);
          } else {
            window.scrollTo({top: nb, behavior: 'smooth'});
          }
          hElement.style.display = 'block';
          hElementTarget.style.top = (marker.top - nb + adjustedHeight) + 'px';
          hElementTarget.style.left = (marker.left) + 'px';
          hElementTarget.style.height = (marker.height) + 'px';
          hElementTarget.style.width = (marker.width) + 'px';
          if (item.position === 'left'){
            tmodel.style.left = (marker.left - 5) + 'px';
            tmodel.style.top = (marker.top + marker.height + 24) + 'px';
          } else if (item.position === 'right'){
            tmodel.style.left = 'calc(' + (marker.right + 5) + 'px - 360px)';
            tmodel.style.top = (marker.top + marker.height + 24 - nb + adjustedHeight) + 'px';
          }
          }
        } else {
          window.scrollTo({top: 0, behavior: 'smooth'});
          hElementTarget.style.height = '0px';
          hElementTarget.style.width = '0px';
          tmodel.style.left = item.index === 7 ? 'calc(100% - 384px)' : 'calc(50% - 180px)';
          tmodel.style.top = item.index === 5 ? 'calc(50% - 265px)' : 'calc(50% - 180px)';
        }
      } else {
        window.scrollTo({top: 0, behavior: 'smooth'});
        hElementTarget.style.height = '0px';
        hElementTarget.style.width = '0px';
        tmodel.style.left = 'calc(50% - 180px)';
        tmodel.style.top = item.index === 5 ? 'calc(50% - 265px)' : 'calc(50% - 180px)';
      }
    });
  }

  closeDialog() {
    this.closeSts = false;
    this.tHiLight.style.display = 'none';
    document.getElementById('htmlContent').style.overflow = 'auto';
    this.dialogRef.close();
  }

  onResize(event) {
    const tdialog = document.getElementById('tutorial-dlg');
    if (this.closeSts){
      this.highlightingElement(this.tutorialSlides[this.currentIndex], tdialog);
    }
  }

}
