<div [ngClass]="{'content-loading':isRefresh,'content-loaded':!isRefresh}">
    <mat-spinner *ngIf="isRefresh" diameter="25" strokeWidth="5"></mat-spinner>
</div>
<mat-toolbar class="display_large_screen">
    <mat-toolbar-row class="global-header-menu bg-header-{{envConfig['oemPlatformAcronym']}} ">
        <div class="gbl-header-color-{{envConfig['oemPlatformAcronym']}} w-full flex items-center justify-between left-0 right-0 z-30">
            <div class="float-left flex min-w-34 w-3/12">
                <div class="mt-1 outline-none ml-4">
                    <a [href]="envConfig['oemUrl']" target="_blank">
                        <img [src]="envConfig | hdrImgSrc" class="w-56" [analyticTagging]="{'eventId':'globalNavLogoFn','srcCtrl':envConfig['oemName']}">
                    </a>
                    <div class="cross-bar"></div>
                </div>
                <div class="mt-2 w-48 h-8 ml-60">
                    <a href="{{appConfigService.getPortalUIPath()}}/index.html#/Platform/workspace/home" class="pb-8">
                        <div class="pt-2 text-white font-sans-thin text-3xl font-thin header-oem-{{envConfig['oemPlatformAcronym']}} 
                        gbl-header-title-{{envConfig['oemPlatformAcronym']}} fontNotoSansThin" [analyticTagging]="{'eventId':'globalNavPlatformFn','srcCtrl':envConfig['oemPlatformName']}">
                            {{envConfig['oemPlatformName']}}</div>
                    </a>
                </div>
            </div>
            <div class="float-right flex items-end w-9/12">
                <div class="text-right w-auto ml-auto global-navigation-target-padding " id="global-navigation-target">
                    <app-mbf-search *ngIf="canLoadHeader" class="pad-right-px-19" [viewSearchBar]="false">
                    </app-mbf-search>
                    <button [analyticTagging]="{'eventId':'globalNavIceCubeTrayFn','srcCtrl':'Ice Cube Tray'}" class="text-white cursor-pointer appsPadding actPadding globalNavIceCub" mat-button matTooltip="Links" [matMenuTriggerFor]="icecube">
                        <i class="text-white zmdi zmdi-apps font26px"></i>
                    </button>
                    <mat-menu #icecube="matMenu" xPosition="before" [overlapTrigger]="false" class="pl-8 pt-1 dropdown !overflow-hidden mat-menu-item-remove-hover icecube-menu-mr" backdropClass="icecubeMenu">
                        <div id="icecube" class="-mt-3 pt-1 arrow-up w-0 h-0 absolute top-0"></div>
                        <div *ngFor="let menu of secondaryMenu; let first = first">
                            <div class="{{first ? '' : 'pt-2'}}"></div>
                            <div class="header-menu-title pb-1">{{menu.title}}</div>
                            <ng-container *ngFor="let submenuItem of menu.submenu">
                                <a *ngIf="(memoizeGetBuildUrl | headerBuildUrl:submenuItem) else notLink; let url" mat-menu-item class="{{submenuItem.title === 'MyBoeingFleet' && mbfHome ? 'text-gray-500' : ''}}" href="{{url}}" [target]="submenuItem.target" [analyticTagging]="{'eventId':'globalNavTrayDropDownExitLinkFn','srcCtrl':[menu.title,submenuItem.title]}">{{submenuItem.title}}
                                </a>
                                <ng-template #notLink>
                                    <span mat-menu-item disabled class="text-gray-500">
                                        {{submenuItem.title}}
                                    </span>
                                </ng-template>
                            </ng-container>
                        </div>
                    </mat-menu>

                    <button [analyticTagging]="{'eventId':'globalNavAccountFn','srcCtrl':'Account'}" class="text-white cursor-pointer actPadding" mat-button matTooltip="Account" [matMenuTriggerFor]="account">
                        <i class="text-white zmdi zmdi-account-circle font26px"></i>
                    </button>
                    <mat-menu #account="matMenu" xPosition="before" [overlapTrigger]="false" class="pt-1 account-dropdown !overflow-hidden mat-menu-item-remove-hover account-menu-mr" backdropClass="accountMenu">
                        <div id="account" class="-mt-3 pt-1 arrow-up w-0 h-0 absolute top-0"></div>
                        <div class="pt-4 pl-12 mb-8 truncate text-left max-w-full relative">
                            <div class="flex-row flex-wrap pt-8">
                                <div class="text-2xl font-bold text-account-header h-12 truncate">{{user.firstName}} {{user.lastName}}
                                </div>
                                <div class="text-xl font-bold text-gray-700 pt-2 truncate">{{user.emailAddress}}</div>
                            </div>
                            <div class="!pt-3.5"></div>
                            <a mat-menu-item href={{yourAccountLink}} style="margin-left: -10px;" target="_blank" class="text-2xl h-16i text-submenu pl-0 c-black" [analyticTagging]="{'eventId':'globalNavAccountDropDownFn','srcCtrl':'Your Account'}">
                                My Account Settings
                            </a>
                        </div>
                        <a mat-menu-item disabled class="filler"></a>
                        <a mat-menu-item (click)="openLogoutModal()" class="text-2xl ml-8 logout-link remove-item-hover uppercase" [analyticTagging]="{'eventId':'globalNavAccountDropDownFn','srcCtrl':'LOG OUT'}">
                            Log out
                        </a>
                    </mat-menu>
                </div>
            </div>
        </div>
    </mat-toolbar-row>
    <mat-toolbar-row class="p-0 h-22i bg-gray-900 text-white font-sans  text-xl leading-5 tracking-wider">
        <div class="w-full flex justify-between items-center">
            <div class="flex justify-between h-full items-center ml-subdiv">
                <div id="myBoeingFleet-pages-target" class="flex">
                    <div *ngFor="let item of secondaryBannerLeft" class="secnd-banner-applications-{{envConfig['oemPlatformAcronym']}} text-center">
                        <div *ngIf="item.title == 'MBF HOME' || item.title == 'APPLICATIONS'" class="padding-b-t hover:border-white border-transparent border-b-2 margin-lr-p5 fontHome" [ngClass]="item.title == currentTab.toUpperCase() ? 'border-white' : 'border-transparent'">
                            <a *ngIf="item | headerAdminLink:user:envConfig" [href]="memoizeGetBuildUrl | headerBuildUrl:item" target="{{item.target}}" class="fw-450" [analyticTagging]="{'eventId':item.analytics?item.analytics.eventId:'','srcCtrl':item.analytics?item.analytics.srcCtrl:''}">{{item.title}}</a>
                        </div>
                    </div>
                </div>
                <div *ngFor="let item of secondaryBannerLeft" class="secnd-banner-applications-{{envConfig['oemPlatformAcronym']}} text-center">
                    <div *ngIf="!(item.title == 'MBF HOME' || item.title == 'APPLICATIONS') && (item | headerAdminLink:user:envConfig)" class="padding-b-t hover:border-white border-transparent border-b-2 margin-lr-p5 fontHome" [ngClass]="item.title == currentTab.toUpperCase() ? 'border-white' : 'border-transparent'">
                        <a [href]="memoizeGetBuildUrl | headerBuildUrl:item" target="{{item.target}}" class="fw-450" [analyticTagging]="{'eventId':item.analytics?item.analytics.eventId:'','srcCtrl':item.analytics?item.analytics.srcCtrl:''}">{{item.title}}</a>
                    </div>
                </div>
            </div>
            <div class="float-right flex">
                <div id="your-MBF-home-page-target">
                    <button *ngIf="this.currentTab == 'MBF Home'" class="text-white cursor-pointer actPadding" mat-button matTooltip="Workspace Options" matTooltipClass="tooltip" [matMenuTriggerFor]="workspaceOption" [analyticTagging]="{'eventId':'secondaryBannerIconsFn','srcCtrl':'Workspace'}">
                        <i class="text-white zmdi zmdi-view-quilt font26px"></i>
                    </button>
                    <mat-menu #workspaceOption="matMenu" [overlapTrigger]="false" class="disable_mat_menu pl-8 dropdown !overflow-hidden mat-menu-item-remove-hover">
                        <div id="workspaceOption" class="-mt-3 ml-0 pt-1 arrow-up w-0 h-0 absolute top-0"></div>
                        <div class="pt-8"></div>
                        <span class="tracking-wider header-menu-title text-px-14">MBF HOME</span>
                        <div class="pt-1"></div>
                        <ng-container *ngIf="!workspaceLocked">
                            <a mat-menu-item class="text-color-black" *ngFor="let item of dashboardItems" (click)="openDashboardDialog(item)" [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}">{{item.title}}
                            </a>
                        </ng-container>
                        <ng-container *ngIf="workspaceLocked">
                            <div *ngFor="let item of dashboardItems" matTooltipPosition="above" [matTooltipDisabled]="item.title == 'Reset home page to default'" matTooltip="Workspace does not support function. Please change your workspace and try again.">
                                <a mat-menu-item class="text-color-black" [ngClass]="{'dashboard-disable':(item.title != 'Reset home page to default')}" [disabled]="item.title != 'Reset home page to default'" (click)="(item.title == 'Reset home page to default') && openDashboardDialog(item)"
                                    [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}">{{item.title}}
                                </a>
                            </div>
                        </ng-container>
                        <div *ngIf="communitiesItems.length > 0">
                            <div class="pt-8"></div>
                            <span class="tracking-wider header-menu-title text-px-14">COMMUNITIES</span>
                            <div class="pt-1"></div>
                            <a mat-menu-item class="text-color-black" *ngFor="let item of communitiesItems" href="{{item.url}}" [target]="item.urlTarget" [analyticTagging]="{'eventId':'secondaryNavDropDownClickFn','srcCtrl':['WORKSPACE',item.shortName]}">{{item.title}}</a>
                        </div>
                    </mat-menu>
                    <button #notificationTrigger="matMenuTrigger" class="text-white cursor-pointer actPadding" mat-button matTooltip="Notifications" matTooltipClass="tooltip" [matMenuTriggerFor]="notification" [disabled]="undismissedNotificationsAvailable" [analyticTagging]="{'eventId':'secondaryBannerNotificationIconFn','srcCtrl':this}">
                        <i class="text-white zmdi zmdi-notifications font26px" [matBadge]=unreadNotifications
                            matBadgeColor="warn" matBadgeSize="small"
                            [matBadgeHidden]="!unreadNotificationsAvailable"></i>
                    </button>
                    <mat-menu #notification="matMenu" [overlapTrigger]="false" class="pt-1 notification-dropdown !overflow-hidden icecube-menu-mr" backdropClass="notificationMenu">
                        <div id="notification" class="-mt-3 pt-1 arrow-up w-0 h-0 absolute top-0"></div>
                        <div class="pt-3"></div>
                        <span class="notification-menu-title font-sans uppercase">Notifications</span>
                        <div class="pt-1"></div>
                        <div class="dropdownStyle">
                            <div class="notificationLine" mat-menu-item *ngFor="let item of notifications">
                                <div class="float-left open-notification-div" (click)="openNotificationDetailsDialog('Details', item)" [analyticTagging]="{'eventId':'notificationFlyout','srcCtrl':this}">
                                    <div class="notificationHeading text-base" [ngClass]="{'read-Heading': item.messageRead }">
                                        <span class="dot" *ngIf="!item.messageRead" [ngClass]="{
                                        'unplanned-dot' : item.messageType === 'UNPLANNED_OUTAGE',
                                        'planned-dot' : item.messageType === 'PLANNED_OUTAGE',
                                        'update-dot' : (item.messageType === 'USER_NOTIFICATION' || item.messageType === 'INFORMATIONAL')
                                        }"></span> {{memoizeGetmessageType | getNotificationHeader:item.messageType }}
                                    </div>
                                    <div class="notificationTitle" [ngClass]="{'read-Title': item.messageRead }">
                                        {{ item.messageTitle }}
                                    </div>
                                </div>
                                <div>
                                    <button *ngIf="item.messageType === 'USER_NOTIFICATION'" mat-button matTooltip="Click to delete the notification" matTooltipClass="tooltip" (click)="deleteNotification(item)">
                                        <mat-icon class="close-icon-notification">close</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="no-notification" *ngIf="notifications.length === 0">
                                <span class="item.messageRead">No active notifications or alerts.</span>
                            </div>
                        </div>
                        <div *ngIf="unreadMessages.length > 0" class="clearUpdates" (click)="clearUpdates()">
                            MARK ALL AS READ
                        </div>
                    </mat-menu>
                </div>
                <button id="need-help-target" class="text-white cursor-pointer actPadding" mat-button matTooltip="Help" matTooltipClass="tooltip" [matMenuTriggerFor]="help" [analyticTagging]="{'eventId':'secondaryBannerIconsFn','srcCtrl':'Help'}">
                    <i class="text-white zmdi zmdi-help font26px"></i>
                </button>
                <mat-menu #help="matMenu" [overlapTrigger]="false" class="help-menu-mr pl-8 dropdown !overflow-hidden mat-menu-item-remove-hover">
                    <div id="help" class="-mt-3 pt-1 arrow-up w-0 h-0 absolute top-0"></div>
                    <div class="pt-3"></div>
                    <span class="header-menu-title">Help</span>
                    <div class="pt-1"></div>
                    <span *ngFor="let item of helpItems">
                        <span *ngIf="item.title=='My Field Service Office';then MyFielderviceLink else otherLinks">
                        </span>
                    <ng-template #MyFielderviceLink>
                        <a *ngIf="!(user && user.airlineCode && user.airlineCode == 'TBC')" mat-menu-item class="" (click)="urlClicked(item)" href="javascript:void(0);" [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}">{{item.title}}</a>
                    </ng-template>
                    <ng-template #otherLinks>
                        <span *ngIf="item.title=='Access Administrator Support';then accessAdminLink else noAccessAdminLinks"></span>
                        <ng-template #accessAdminLink>
                            <a class="" *ngIf="accessAdministratorFlag" mat-menu-item (click)="urlClicked(item)" href="javascript:void(0);" [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}">{{item.title}}</a>
                        </ng-template>
                        <ng-template #noAccessAdminLinks>
                            <span *ngIf="item.title=='Product and Data Access (Access Summary)';then accessSummaryLink else noAccessSummaryLinks"></span>
                            <ng-template #accessSummaryLink>
                                <a class="" mat-menu-item (click)="urlClicked(item)" href="javascript:void(0);" [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}">{{item.title}}</a>
                            </ng-template>
                            <ng-template #noAccessSummaryLinks>
                                <a mat-menu-item class="" (click)="item.title=='Tutorial' ? openTutorialDialog('Tutorial') : urlClicked(item)" href="javascript:void(0);" [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}">{{item.title}}</a>
                            </ng-template>
                        </ng-template>
                    </ng-template>

                    </span>
                </mat-menu>
            </div>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
<mat-toolbar class="display_side_Menu ">
    <mat-toolbar-row class="p-0 global-header-menu bg-header-{{envConfig[ 'oemPlatformAcronym']}} ">
        <div class="gbl-header-color-{{envConfig[ 'oemPlatformAcronym']}} w-full flex justify-between items-center left-0 right-0 z-30 ">
            <div class="ml-6 mt-1 outline-none ">
                <a [href]="envConfig[ 'oemUrl'] " target="_blank ">
                    <img [src]="envConfig | hdrImgSrc " class="w-56 " [analyticTagging]="{ 'eventId': 'globalNavLogoFn', 'srcCtrl':envConfig[ 'oemName']} ">
                </a>
                <div class="cross-bar "></div>
            </div>
        </div>
        <div *ngIf="hideSearch" class="pr-8">
            <button class="text-white cursor-pointer float-right search-icon" (click)="search()" mat-button>
                <i class="text-white zmdi zmdi-search text-4.5xli padding-6-px" matTooltip="Search"  [matTooltipClass]="'search-tooltip'"></i>
            </button>
        </div>
    </mat-toolbar-row>
    <mat-toolbar-row class="padding-0px height-row bg-gray-900 text-white font-sans text-xl leading-5 tracking-wider flex justify-between ">
        <div *ngIf="!searchBar" class="pl-8 ">
            <button mat-icon-button (click)="openSideNav() ">
                <mat-icon class="mr-8 text-px-30">menu</mat-icon>
            </button>
            <span>
                {{currentTab}}
            </span>
        </div>
        <div class="w-full">
            <app-mbf-search class="w-full" [viewSearchBar]="true" [showSearch]="showSearch" (showSearchEvent)="updateShowSearchValue($event)"></app-mbf-search>
        </div>
    </mat-toolbar-row>
</mat-toolbar>