import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'src/app/core/app-config.service';
import { WorkspaceService } from 'src/app/workspace/workspace.service';
import { UserApiService } from 'src/app/user/user-api.service';
import { User } from 'src/app/user/user';
import { UserRolesItem } from 'src/app/user/user-roles-item';
import { HeaderService } from 'src/app/header/header.service';
import { LogoutService } from '../services/logout/logout.service';
import { HeaderData, HeaderMenu, HeaderSubmenu } from './header-data';
import { switchMap, tap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { Workspace } from 'src/app/workspace/workspace';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
  selector: 'app-header-base',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderBaseComponent implements OnInit {
  headerData: HeaderData;
  primaryMenu = [] as HeaderMenu[];
  secondaryMenu: HeaderMenu[] = [] as HeaderMenu[];
  secondaryBannerLeft = [] as HeaderSubmenu[];
  helpItems = [] as HeaderSubmenu[];
  dashboardItems = [] as HeaderSubmenu[];
  communitiesItems = [] as HeaderSubmenu[];
  user = {
    firstName: '',
    lastName: '',
    userRoles: [] as UserRolesItem[]
  } as User;
  envConfig = {};
  logoutDialog: any;
  logoutConfirmed = false;
  logoutUrl = '';
  logoutFedProxySaml='';
  appLogout='';
  communityBase: string;
  mbfHome = false;
  prtlWelcomeMsgPrefixNew: string;
  prtlWelcomeMsgPrefix: string;
  prtlWelcomeMsgSuffix: string;
  profileMenuDefaultMsg: string;
  profileMenuNameMsg: string;
  profileMenuMessage: string;
  profileEmailAddress: string;
  canLoadHeader = false;
  workspaceLocked = false;
  accessAdministratorFlag = false;
  isRefresh = false;

  constructor(
    public cd: ChangeDetectorRef,
    public headerService: HeaderService,
    public appConfigService: AppConfigService,
    public userApiService: UserApiService,
    public matDialog: MatDialog,
    public httpClient: HttpClient,
    public router: Router,
    public location: Location,
    public workspaceService: WorkspaceService,
    public snackBar: MatSnackBar,
    public logoutService: LogoutService
  ) { }

  ngOnInit(): void {
    this.communityBase = `${this.appConfigService.getPortalUIPath()}/index.html#/Platform/workspace/`;
    this.userApiService.getUser().subscribe((user: User) => {
      this.user = user;
      if (!localStorage.getItem('welcomeMsgAndNotificationDisplayed')) {
        this.setupInitialMessage();
      }
    });
    this.appConfigService.envConfig.pipe(
      tap(config => { this.envConfig = config; this.setLogoutUrl(); }),
      switchMap(config => this.headerService.getHeaderData(config[`oemPlatformAcronym`]))
    ).subscribe((response: HeaderData) => {
      if (response) {
        this.headerData = response;
        this.primaryMenu = response.primaryMenu;
        this.secondaryMenu = response.secondaryMenu;
        this.secondaryMenu.forEach(item => {
          item.submenu.forEach(val =>{
            if(val.type === 'rp'){
              val.url = this.envConfig["rpAlias"] + val.url;
            }
          })
        });
        // TODO should not hardcode TBC here, should get list from env_config
        this.secondaryBannerLeft = this.headerData.secondaryBannerLeft
          .filter(item => !(item.title === `APIs` && this.envConfig[`airlineCode`] !== `TBC`));
        this.helpItems = this.headerData.help;
        
        this.helpItems.filter(item => {
          if(item.type === 'rp'){
            item.url = this.envConfig["rpAlias"] + item.url;
          }
        });
        this.dashboardItems = this.headerData.dashboard;
        this.headerService.workspaceLockSts.subscribe((data: any) => {
          this.workspaceLocked = data;
        });
        this.canLoadHeader = true;
      }
    }, error => {
      this.canLoadHeader = true;
    });
    this.setMbfHome();
    this.router.events.subscribe(val => {
      this.setMbfHome();
    });
    const workspaceList = this.workspaceService.getWorkspacesProp(true);
    workspaceList.subscribe(workspaces => {
      if (workspaces.data && Array.isArray(workspaces.data)) {
        this.communitiesItems = workspaces.data.filter((workspace: Workspace) => workspace.unlisted === false)
          .map((workspace: Workspace) => this.buildWorkspaceLink(workspace));
      }
    });
    this.cd.markForCheck();
    this.headerService.logoutClicked.subscribe((data: boolean) => {
      this.isRefresh = data;
      this.cd.markForCheck();
    });
  }
  buildWorkspaceLink(workspace: Workspace) {
    return {
      title: workspace.longName,
      url: `${this.communityBase}${workspace.id}`,
      urlTarget: '', type: '',
      shortName: workspace.shortName
    };
  }
  setLogoutUrl(): void {
    this.logoutUrl = this.envConfig['logoutUrl'];
    this.logoutFedProxySaml=this.envConfig['logoutFedProxySaml'];
    this.appLogout=this.envConfig['appLogout'];
  }

  hdrImgSrc(): string {
    // tslint:disable-next-line: no-string-literal
    return this.envConfig['oemPlatformAcronym'] ? `assets/img/logos/oem-logo-${this.envConfig['oemPlatformAcronym']}-GlobalHeader.png` : '';
  }

  buildUrl(item: HeaderSubmenu) {
    return this.buildUrlFromString(item.url, item.type);
  }

  buildUrlFromString(url: string = '', type: string) {
    return this.headerService.buildUrlFromString(url, type, this.envConfig);
  }

  openLogoutModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      type: 'error',
      title: 'Are you sure you would like to logout?',
      actions: [{ func: 'closeModal', text: 'No' }, { func: 'actionFunction', text: 'Yes' }]
    };
    const logoutDialog = this.matDialog.open(ConfirmationModalComponent, dialogConfig);
    logoutDialog.afterClosed().subscribe(logoutConfirmed => {
      this.logoutConfirmed = logoutConfirmed;
      if (this.logoutConfirmed) {
        this.snackBar.dismiss();
        this.logoutUser();
      }
    });
  }

  public logoutUser(): void {
    this.isRefresh = true;
    this.logoutService.logout(true);
  }

  setMbfHome() {
    this.mbfHome = (this.location.path() === ''
      || this.location.path() === this.appConfigService.getRoute(`mbfHome`)
      || this.location.path() === this.appConfigService.getRoute(`mbfHomeWorkspace`));
  }

  setupInitialMessage() {
    if (!window.location.href.includes('/Platform/browserEOL')) {
      this.prtlWelcomeMsgPrefixNew = this.appConfigService.getProperty(`prtlWelcomeMsgPrefixNew`);
      this.prtlWelcomeMsgPrefix = this.appConfigService.getProperty(`prtlWelcomeMsgPrefix`);
      this.prtlWelcomeMsgSuffix = this.appConfigService.getProperty(`prtlWelcomeMsgSuffix`);
      this.profileMenuDefaultMsg = this.appConfigService.getProperty(`profileMenuDefaultMsg`);
      this.profileMenuNameMsg = this.appConfigService.getProperty(`profileMenuNameMsg`);
      if (!window.location.href.includes('/Platform/browserEOL')) {
        this.sendUserNotification(this.setupWelcomeMessage());
      }
      this.setupProfileMenuMessage();
      this.cd.markForCheck();
    }
    this.cd.markForCheck();
  }

  /**
   * Set welcome message
   * @returns welcome message
   */
  setupWelcomeMessage() {
    let portalWelcomeMessage = this.prtlWelcomeMsgPrefixNew ? this.prtlWelcomeMsgPrefixNew : 'Welcome';
    if (this.user && !this.userApiService.isNewUser(this.user)) {
      portalWelcomeMessage = this.prtlWelcomeMsgPrefix ? this.prtlWelcomeMsgPrefix : 'Welcome back';
      if (this.user.firstName) {
        portalWelcomeMessage += ' ' + this.user.firstName;
      } else if (this.user.fullName) {
        portalWelcomeMessage += ' ' + this.user.fullName;
      }
    }
    portalWelcomeMessage += this.prtlWelcomeMsgSuffix ? this.prtlWelcomeMsgSuffix : '!';
    this.cd.markForCheck();
    return portalWelcomeMessage;
  }

  setupProfileMenuMessage() {
    this.profileMenuMessage = (this.profileMenuDefaultMsg) ? this.profileMenuDefaultMsg : 'Hello!';
    this.profileEmailAddress = '';
    if (this.user && this.user.fullName) {
      this.profileMenuMessage = this.user.fullName;
      this.profileEmailAddress = this.user.emailAddress;
    } else if (this.user && this.user.firstName) {
      this.profileMenuMessage = this.user.firstName;
      this.profileEmailAddress = this.user.emailAddress;
    }
    this.cd.markForCheck();
  }
  sendUserNotification(message: string): void {
    const horizontalPositionVal: MatSnackBarHorizontalPosition = 'right';
    const verticalPositionVal: MatSnackBarVerticalPosition = 'top';
    const snackBarRef = this.snackBar.open(message, '', {
      duration: 1000,
      horizontalPosition: horizontalPositionVal,
      verticalPosition: verticalPositionVal,
      panelClass: 'notification-custom-snackbar'
    });
    this.cd.markForCheck();
  }
}
