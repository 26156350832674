// Common module injection
import '@angular/compiler';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
// Pipe injection
import { HighlightTextPipeModule } from './pipes/shared/highlight-text/highlight-text.module';
import { ReplaceTextPipeModule } from './pipes/shared/replace-text/replace-text.module';
// Component injection
import { BaseAdapter } from './core/interfaces/adapter';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient, HttpClientXsrfModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppConfigService, Configuration } from './core/app-config.service';
import { ConfirmationModalComponent } from './shared/confirmation-modal/confirmation-modal.component';
import { HeaderMBFComponent } from './header/header-mbf.component';
import { HeaderBaseComponent } from './header/header-base.component';
import { FooterMBFComponent } from './footer/footer-mbf.component';
import { FooterBaseComponent } from './footer/footer-base.component';
import { HeaderMbfSearchComponent } from './header/style2 search/header-mbf-search.component';
import { ConfirmationAlertComponent } from './shared/confirmation-alert/confirmation-alert.component';
import { httpInterceptorProviders } from './interceptors';
import { CategoryListByUserAccessPipe } from './pipes/header-search/category-list-by-user-access.pipe';
import { BackToTopComponent } from './back-to-top/back-to-top.component';
import { MbfSearchComponent } from './header/mbf-search/mbf-search.component';
import { ResetHomeToDefaultDialogComponent } from './workspace-options/reset-home-to-default-dialog/reset-home-to-default-dialog.component';
import { ViewFolderArchiveDialogComponent } from './workspace-options/view-folder-archive-dialog/view-folder-archive-dialog.component';
import { AnalyticTaggingModule } from './directives/shared/analytic-tagging/analytic-tagging.module';
import { SharedModule } from './shared-modules/shared.module';
import { HeaderBuildUrlPipe } from './pipes/header/header-build-url.pipe';
import { GetNotificationHeaderPipe } from './pipes/header/get-notification-header.pipe';
import { CookieBannerComponent } from './privacy/cookie-banner/cookie-banner.component';
import { BackToTopDirective } from './directives/back-to-top/back-to-top.directive';
import { SafePipeModule } from './pipes/shared/safe/safe.module';
import { StriphtmlPipeModule } from './pipes/shared/striphtml/striphtml.module';
import { MainMaterialModule } from './material/main-material.module';
import { CustomCamelCaseModule } from './pipes/shared/custom-camel-case/custom-camel-case.module';
import { FooterBuildLinkPipe } from './pipes/footer/footer-build-link.pipe';
import { HeaderAdminLinkPipe } from './pipes/header/header-admin-link.pipe';
import { HdrImgSrcPipe } from './pipes/header/hdr-img-src.pipe';
import { HeaderMbfSidenavComponent } from './header-mbf-sidenav/header-mbf-sidenav.component';
import { TutorialDialogComponent } from './header/tutorial-dialog/tutorial-dialog.component';
import { SearchResultsTutorialDialogComponent } from './header/search-results-tutorial-dialog/search-results-tutorial-dialog.component';
import { ApplistTutorialDialogComponent } from './header/applist-tutorial-dialog/applist-tutorial-dialog.component';
import { CustomSnackbarComponent } from './notification/custom-snackbar/custom-snackbar.component';
import { AppInsightMonitoringService } from './services/shared/app-insight-monitoring.service';
import { BrowserEolComponent } from './browser-eol/browser-eol.component';
import { BrowserEolDialogComponent } from './browser-eol/browser-eol-dialog/browser-eol-dialog.component';
import { RedarInterceptDialogComponent } from './browser-eol/redar-intercept-dialog/redar-intercept-dialog.component';
import { CompoundCountComponent } from './shared/compound-count/compound-count.component';



const initializeAppConfig = (appConfig: AppConfigService) => {
  return (): Promise<Configuration> => {
    return appConfig.init(window.location.href.indexOf('/Login') === -1);
  };
};

const APP_COMPONENTS = [
  AppComponent,
  HeaderBaseComponent,
  ConfirmationModalComponent,
  HeaderMBFComponent,
  FooterMBFComponent,
  ConfirmationModalComponent,
  ConfirmationAlertComponent,
  FooterBaseComponent,
  HeaderMbfSearchComponent,
  ConfirmationAlertComponent,
  CategoryListByUserAccessPipe,
  BackToTopComponent,
  MbfSearchComponent,
  ResetHomeToDefaultDialogComponent,
  ViewFolderArchiveDialogComponent,
  HeaderBuildUrlPipe,
  GetNotificationHeaderPipe,
  CookieBannerComponent,
  BackToTopDirective,
  FooterBuildLinkPipe,
  HdrImgSrcPipe,
  HeaderAdminLinkPipe,
  HeaderMbfSidenavComponent,
  TutorialDialogComponent,
  SearchResultsTutorialDialogComponent,
  ApplistTutorialDialogComponent,
  CustomSnackbarComponent,
  BrowserEolComponent,
  BrowserEolDialogComponent,
  RedarInterceptDialogComponent,
  CompoundCountComponent,

];

@NgModule({
  declarations: APP_COMPONENTS,
  imports: [
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'CSRF-TOKEN',
      headerName: 'X-CSRF-TOKEN'
    }),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FlexLayoutModule,
    AnalyticTaggingModule,
    SharedModule,
    HighlightTextPipeModule,
    ReplaceTextPipeModule,
    SafePipeModule,
    StriphtmlPipeModule,
    MainMaterialModule,
    CustomCamelCaseModule
  ],
  exports: APP_COMPONENTS,
  providers: [
    httpInterceptorProviders,
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppConfig,
      multi: true,
      deps: [AppConfigService, HttpClient]
    },
    BaseAdapter,
    AppInsightMonitoringService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
