<div [ngClass]="action=='Contact' || action =='Details'?'contact-style' :'action-style'">
    <h2 mat-dialog-title *ngIf="action !='Delete' && action !='Publish'" class="m-0">
        <span *ngIf="action =='Preview'">Preview</span>
        <span *ngIf="action =='Contact'">Contact Information</span>
        <span *ngIf="action =='Applications'">Applications</span>
    </h2>
    <div>
        <span *ngIf="action =='Details'" class="titleForNotification"> {{ dialogData.messageTitle }} </span>
    </div>
    <div *ngIf="action =='Details'" class="messageType text-base">
        <span class="dot" [ngClass]="{'unplanned-dot' : dialogData.messageType === 'UNPLANNED_OUTAGE',
            'planned-dot' : dialogData.messageType === 'PLANNED_OUTAGE',
            'update-dot' : (dialogData.messageType === 'USER_NOTIFICATION' || dialogData.messageType === 'INFORMATIONAL')}">
        </span> {{ getNotificationsHeader(dialogData.messageType) }}
    </div>
    <mat-dialog-content [ngClass]="action=='Preview' ||  action =='Contact' || action !='Details'?'' :'mt-20px'">
        <div *ngIf="(action != 'Delete' && action !='Publish'); else elseTemplate">
            <div *ngIf="action =='Preview' || action =='Details'">
                <span *ngIf="action =='Preview'" class="text-px-14">{{dialogData.messageTitle}}<br></span>
                <br *ngIf="action !='Details'">
                <p *ngIf="dialogData.messageText != null && dialogData.contactInformation != null">
                    <iframe frameborder="0" class="iframeContent" width="100%" [appTinymceRichTextContent]="dialogData.messageText + dialogData.contactInformation">
                    </iframe>
                </p>
                <p *ngIf="dialogData.contactInformation == null">
                    <iframe frameborder="0" class="iframeContent" width="100%" [appTinymceRichTextContent]="dialogData.messageText">
                    </iframe>
                </p>
                <p *ngIf="dialogData.messageText == null">
                    <iframe frameborder="0" class="iframeContent" width="100%" [appTinymceRichTextContent]="dialogData.messageText">
                    </iframe>
                </p>
            </div>
            <div *ngIf="action =='Contact'">
                <p>
                    <iframe frameborder="0" width="100%" [appTinymceRichTextContent]="dialogData.contactInformation">
                    </iframe>
                </p>
            </div>
            <div class="text-px-14" *ngIf="action =='Applications'">
                This system message applies to {{dialogData.widgets.length}} applications.
                <br><br>
                <div *ngIf="dialogData.widgets.length > 0">
                    <div *ngFor="let filterGroups of dialogData.filterValue.groups">
                        <span *ngFor="let condition of filterGroups.conditions">
                            <span *ngIf="condition.type === 'application'">
                                <p>Included : </p>
                                <p class="pl-6" *ngFor="let widget of condition.values">{{widget.name}}</p>
                            </span>
                        </span>
                        <span *ngFor="let exception of filterGroups.exceptions">
                            <span *ngIf="exception.type === 'application'">
                                <p>Excluded : </p>
                                <p class="pl-6" *ngFor="let widget of exception.values">{{widget.name}}</p>
                            </span>
                        </span>

                    </div>
                </div>
            </div>
            <div *ngIf="action == 'CancelConfirmation'">
                <mat-icon class="text-px-60">error_outline</mat-icon>
                <h2 class="text-px-18">Cancel System Message?</h2>
                <p class="text-px-14 message-padding">Would you like to cancel edting of the system message?</p>
            </div>
            <div *ngIf="action =='Details'">
                <div class="text-px-14">Posted: <span>&nbsp;{{dialogData.createdDateTime | date:
                        'medium'}}</span></div>
            </div>

        </div>

        <ng-template #elseTemplate>
            <mat-icon class="text-px-60">error_outline</mat-icon>
            <p class="text-px-14 message-padding">Are you sure you want to {{action.toLowerCase()}} this system message?
            </p>
        </ng-template>
    </mat-dialog-content>

    <mat-dialog-content class="sys_msg_dialog">

        <div *ngIf="action == 'ServiceUnavailable'">
            <h2 class="text-px-18">System Update</h2>
            <p class="text-px-14 message-padding ">Please try again later, this will take a few minutes.</p>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions [ngClass]="action !='Details' || (action=='Details' && dialogData.messageType !='USER_NOTIFICATION' ) ? 'dialog-style' : 'notification-style' ">
        <button mat-button color="accent" tabindex="-1 " class="uppercase " *ngIf="action=='Details' && dialogData.messageType=='USER_NOTIFICATION' " (click)="doAction() ">Delete</button>
        <button tabindex="-1 " mat-button color="accent" class="uppercase " *ngIf="action=='Delete' || action=='Publish' " (click)="closeDialog() ">Cancel</button>
        <button tabindex="-1 " mat-button color="accent" class="uppercase " (click)="doAction() " *ngIf="action=='Delete' || action=='Publish' ">{{action}}</button>
        <button tabindex="-1 " mat-button color="accent" class="uppercase " (click)="closeDialog() " *ngIf="action !='Delete' && action !='Publish' && action !='CancelConfirmation' && action !='Details' && action !='ServiceUnavailable'
                " [routerLink]="[ '/Admin/SystemMessageAdministration/Edit/', dialogData.messageId] ">Edit</button>
        <button tabindex="-1 " mat-button color="accent" class="uppercase " *ngIf="action !='Delete' && action !='Publish' && action !='ServiceUnavailable' " (click)="closeDialog() ">Close</button>
        <button tabindex="-1 " mat-button color="accent" class="uppercase " *ngIf="action=='CancelConfirmation' " [routerLink]="[ '/Admin/SystemMessageAdministration/Display'] " (click)="closeDialog() ">Cancel</button>

    </mat-dialog-actions>

    <mat-dialog-actions [ngClass]="action !='Details' || (action=='Details' && dialogData.messageType !='USER_NOTIFICATION' ) ? 'dialog-style' : 'notification-style' ">

        <button tabindex="-1 " mat-button class="uppercase sys_msg_btn " *ngIf="action=='ServiceUnavailable' " (click)="closeDialog() ">OKAY</button>

    </mat-dialog-actions>
</div>