import { Injectable } from '@angular/core';
import { HttpResponse, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from '../core/app-config.service';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { catchError, concatMap, delay, map, retryWhen, shareReplay, timeout } from 'rxjs/operators';
import { ContainerWidgetFormat, CompleteWidgetdata, Template, ConstructWidgetOptions } from './widget-data';
import { MarketingWidget } from './marketing-widget/marketing-widget';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  mbdTimeoutValue = 15000;
  marketingWidgetContent = new BehaviorSubject(null);
  marketingWidgetContentForTrainingHub = new BehaviorSubject(null);
  marketingWidgetContentForApplicationsPage = new BehaviorSubject(null);
  marketingBannerType = new BehaviorSubject(null);
  marketingBannerTypeObservable = this.marketingBannerType.asObservable();

  marketingWidgetContentObservable = this.marketingWidgetContent.asObservable();
  marketingWidgetContentForTrainingHubObservable = this.marketingWidgetContentForTrainingHub.asObservable();
  marketingWidgetContentForApplicationsPageObservable = this.marketingWidgetContentForApplicationsPage.asObservable();
  folderDrop = new BehaviorSubject(false);
  folderDropObservable = this.folderDrop.asObservable();
  private readonly cacheApi: Map<string, Observable<string[]>> =
    new Map<string, Observable<string[]>>();
  constructor(private httpClient: HttpClient, private appConfigService: AppConfigService) { }
  arr = [];
  setMarketingWidgetContent(marketingContent: any, isTrainingHub: boolean, isApplicationsPage: boolean): void {
    if (!isTrainingHub && !isApplicationsPage) {
      this.marketingWidgetContent.next(marketingContent);
    } else if (!isTrainingHub) {
      this.marketingWidgetContentForTrainingHub.next(marketingContent);
    }
    else {
      this.marketingWidgetContentForApplicationsPage.next(marketingContent);
    }

  }
  setbannerType(bannerType: string) {
    this.marketingBannerType.next(bannerType);
  }

  setFolderDrop(value: boolean): void {
    this.folderDrop.next(value);
  }

  getWidget(widgetId: string) {
    const endpoint = this.appConfigService.getBackendPath('getWidget') + '/' + widgetId;
    return this.httpClient.get(endpoint).pipe(catchError(this.error));
  }

  getcontentData(widgetId: string, withCache: boolean = true) {
    const endpoint = this.appConfigService.getBackendPath('widgetsEndPoint') + '/contentData' + '/' + widgetId;
    return this.getXHRCall(endpoint, withCache);
  }

  getImageData(url: string, withCache: boolean = true) {
    const endpoint = `${this.appConfigService.getPortalNgPath()}` + '/' + url;
    return this.httpClient.get(endpoint, { responseType: 'blob' });
  }

  private getXHRCall(endpoint, withCache: boolean = true): Observable<any> {
    if (withCache) {
      if (!this.cacheApi[endpoint]) {
        this.cacheApi[endpoint] = this.httpClient.get<any>(endpoint).pipe(shareReplay(1), catchError(this.error));
      }
    }
    else {
      this.cacheApi[endpoint] = this.httpClient.get<any>(endpoint).pipe(shareReplay(1), catchError(this.error));
    }
    return this.cacheApi[endpoint];
  }

  private getXHRCallBanner(endpoint, withCache: boolean = false): Observable<any> {
    if (withCache) {
      if (!this.cacheApi[endpoint]) {
        this.cacheApi[endpoint] = this.httpClient.get<any>(endpoint).pipe(shareReplay(1), catchError(this.error));
      }
    }
    else {
      this.cacheApi[endpoint] = this.httpClient.get<any>(endpoint).pipe(shareReplay(1), catchError(this.error));
    }
    return this.cacheApi[endpoint];
  }

  private getMBDTimeOutXHRCall(endpoint, withCache: boolean = false): Observable<any> {
    if (withCache) {
      if (!this.cacheApi[endpoint]) {
        this.cacheApi[endpoint] = this.httpClient.get<any>(endpoint).pipe(shareReplay(1), catchError(this.error));
      }
    }
    else {
      this.cacheApi[endpoint] = this.httpClient.get<any>(endpoint).pipe(shareReplay(1), catchError(this.error));
    }
    return this.cacheApi[endpoint];
  }
  getWidgetTemplate() {
    const fieldList = 'templateId,implClassName';
    const endpoint = this.appConfigService.getBackendPath('getWidgetTemplate');
    if (!this.cacheApi[endpoint]) {
      this.cacheApi[endpoint] = this.httpClient.get<any>(endpoint,
        { observe: 'response', params: { fields: fieldList } }).pipe(shareReplay(1), catchError(this.error));
    }
    return this.cacheApi[endpoint];
  }

  getWidgetUniqueName(name: string, type: string) {
    const endpoint = this.appConfigService.getBackendPath('getWidgetUniqueName');
    return this.httpClient.get<any>(endpoint, { observe: 'response', params: { widgetName: name, types: type } })
      .pipe(catchError(this.error));
  }

  createWidget(completeWidgetData: CompleteWidgetdata) {
    const endpoint = this.appConfigService.getBackendPath('getWidget');
    return this.httpClient.post<any>(endpoint, completeWidgetData).pipe(catchError(this.error));
  }

  /**
   * Method to fetch all the archived folders.
   */
  getArchivedWidgets(): Observable<ContainerWidgetFormat[]> {
    const endpoint = this.appConfigService.getBackendPath('getWidget') + '?userArchived=true';
    return this.httpClient.get<any>(endpoint, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) =>
        response.body.data,
        catchError(this.error)));
  }

  /**
   * Method to unarchive selected folders into workspace
   * @param toWidgetId target Workspace id where the folder is to be unarchived
   * @param folderData Folder info that is to be unarchived
   */
  restoreWidgets(toWidgetId: string, folderData: Map<string, string>) {
    const convMap = {};
    folderData.forEach((val: string, key: string) => {
      convMap[key] = val;
    });
    const endpoint = this.appConfigService.getBackendPath('getWidget') + '/' + toWidgetId + '/restoreWidgets';
    return this.httpClient.post<any>(endpoint, convMap).pipe(catchError(this.error));
  }

  updateWidget(completeWidgetData: CompleteWidgetdata) {
    const endpoint = this.appConfigService.getBackendPath('getWidget') + '/' + completeWidgetData.widgetData.id;
    return this.httpClient.post<any>(endpoint, completeWidgetData).pipe(catchError(this.error));
  }

  removeWidget(widgetId: string) {
    const endpoint = this.appConfigService.getBackendPath('widgetLayout') + '/' + widgetId + '/' + 'remove';
    return this.httpClient.post<any>(endpoint, {}).pipe(catchError(this.error));
  }

  getMarketingContentForTrainingHub(withCache: boolean = true, withSubscribe: boolean = false): Observable<MarketingWidget[]> {
    const endpoint = this.appConfigService.getBackendPath('marketingWidgetEndpoint') + '/' + 'trainingHub';
    const callXhr = this.getXHRCallBanner(endpoint, false);
    if (withSubscribe) {
      callXhr.subscribe((response) => {
        this.setMarketingWidgetContent(response, true, false);
      });
    }
    return callXhr;
  }
  getMarketingContentForApplicationsPage(withCache: boolean = true, withSubscribe: boolean = false): Observable<MarketingWidget[]> {
    const endpoint = this.appConfigService.getBackendPath('marketingWidgetEndpoint') + '/' + 'appList';
    const callXhr = this.getXHRCallBanner(endpoint, false);
    if (withSubscribe) {
      callXhr.subscribe((response) => {
        this.setMarketingWidgetContent(response, false, true);
      });
    }
    return callXhr;
  }

  getMarketingContent(withCache: boolean = true, withSubscribe: boolean = false): Observable<MarketingWidget[]> {
    const endpoint = this.appConfigService.getBackendPath('marketingWidgetEndpoint') + '/' + 'home';
    const callXhr = this.getXHRCallBanner(endpoint, false);
    if (withSubscribe) {
      callXhr.subscribe((response) => {
        this.setMarketingWidgetContent(response, false, false);
      });
    }
    return callXhr;
  }
  /**
   * Method to transfer widget from one widget to other widget
   * @param currentParent Current Parent Id of the widget
   * @param layoutToTransfer Id of the widget to be transfered
   * @param toWidgetId New Parent Id to which the widget is to be transfered
   */
  transferWidget(currentParent: string, layoutToTransfer: string, toWidgetId: string) {
    const endpoint = this.appConfigService.getBackendPath('getWidget') + '/' + currentParent + '/layouts'
      + '/' + layoutToTransfer + '/transfer?toWidgetId=' + toWidgetId;
    return this.httpClient.post<any>(endpoint, {}).pipe(catchError(this.error));
  }

  archiveWidget(widgetId: string) {
    const endpoint = this.appConfigService.getBackendPath('getWidget') + '/' + widgetId + '/archive';
    return this.httpClient.post<any>(endpoint, {}).pipe(catchError(this.error));
  }

  removeFolderWidget(widgetId: string) {
    const endpoint = this.appConfigService.getBackendPath('getWidget') + '/' + widgetId + '/remove';
    return this.httpClient.post<any>(endpoint, {}).pipe(catchError(this.error));
  }

  sendWidget(widgetRequest: any) {
    const endpoint = this.appConfigService.getBackendPath('getWidget') + '/' + widgetRequest.widgetId + '/copy';
    return this.httpClient.post<any>(endpoint, widgetRequest).pipe(catchError(this.error));
  }

  /**
   * Method to fetch the KPI List Widget Order Details
   * @param orderType Type of Order
   * @param ccid CCID
   * @param requestedOrderCount Count of requested Orders
   */
  getKpiWidgetUserByTypeAndCcidAndCount(orderType: string, ccid: string, requestedOrderCount: number, withCache: boolean = false) {
    // tslint:disable-next-line: max-line-length
    const endpoint = this.appConfigService.getBackendPath('getWidget') + '/partKpiList' + '/' + orderType + '/' + ccid + '/' + requestedOrderCount;
    return this.getMBDTimeOutXHRCall(endpoint, withCache).pipe(timeout(this.mbdTimeoutValue));
  }

  /**
   * Method to get the Total order count details
   * @param ccid CCID
   */
  getPartKpiCategoryDetails(ccid: string, withCache: boolean = false) {
    const endpoint = this.appConfigService.getBackendPath('getWidget') + '/partKpiList' + '/' + ccid;
    return this.getMBDTimeOutXHRCall(endpoint, withCache).pipe(timeout(this.mbdTimeoutValue));
  }
  /**
   * Method to get the Columns to display along with column order and sort order of MRCM
   * @param layoutId Widget's layout Id
   */
  getUserWidgetData(layoutId: string, withCache: boolean = true) {
    const endpoint = this.appConfigService.getBackendPath('toggleLayout') + '/' + layoutId;
    return this.getXHRCall(endpoint, withCache);
  }

  /**
   * Method to save the Sort order/Column Order of MRCM
   * @param layoutId Widget's layout Id
   * @param tableKey Column Order/Sort Order
   * @param value Value to be saved
   */
  saveMRCMTableInfo(layoutId: string, tableKey: string, value: any) {
    const endpoint = this.appConfigService.getBackendPath('toggleLayout') + '/' + layoutId + '/' + tableKey;
    return this.httpClient.post<any>(endpoint, value).pipe(catchError(this.error));
  }

  /**
   * Method to get data from mock proxy service
   * @param widgetType type of the widget for which data is to retrieved from proxy service
   */
  mockProxyService(widgetType: string) {
    const endpoint = this.appConfigService.getBackendPath('proxyRequest') + '/' + widgetType;
    return this.httpClient.post<any>(endpoint, {}).pipe(catchError(this.error));
  }

  /**
   * Method to get data from proxy service for MRCM widget
   * @param proxyServiceUrl Proxy service URL
   * @param apimkey APIM Key
   * @returns 
   */
  proxyService(proxyServiceUrl: string, apimkey: string) {
    const serviceRequestData: any = {};
    const serviceRequestDataHeaders: any = {};
    const serviceRequestDataPortalData: any = {};
    serviceRequestDataHeaders['Content-Type'] = 'application/json';
    serviceRequestDataHeaders.Accept = 'application/json';
    serviceRequestDataHeaders['Boeing-APIM-Key'] = apimkey;
    serviceRequestDataPortalData.PROXY_SERVICE_PORTAL_DATA_AIRLINE_CODE = 'CCID';
    serviceRequestData.headers = serviceRequestDataHeaders;
    serviceRequestData.systemData = serviceRequestDataPortalData;
    serviceRequestData.serviceUrl = proxyServiceUrl;
    serviceRequestData.requestMethod = 'GET';
    serviceRequestData.dataFormat = 'json';
    const endpoint = this.appConfigService.getBackendPath('proxyRequest');
    return this.httpClient.post<any>(endpoint, serviceRequestData).pipe(catchError(this.error));
  }

  bsrsProxyService(srParams: any, proxyServiceUrl: string, apimkey: string) {
    const params: any = {};
    params.numRows = srParams.numRows;
    params.view = 'dashboard';
    const serviceRequestData: any = {};
    const serviceRequestDataHeaders: any = {};
    const serviceRequestDataPortalData: any = {};
    serviceRequestDataHeaders['Content-Type'] = 'application/json';
    serviceRequestDataHeaders.Accept = 'application/json';
    serviceRequestDataHeaders['Boeing-APIM-Key'] = apimkey;
    serviceRequestData.headers = serviceRequestDataHeaders;
    serviceRequestData.systemData = serviceRequestDataPortalData;
    serviceRequestData.serviceUrl = proxyServiceUrl;
    serviceRequestData.suppliedData = params;
    serviceRequestData.requestMethod = 'GET';
    const endpoint = this.appConfigService.getBackendPath('proxyRequest');
    return this.httpClient.post<any>(endpoint, serviceRequestData).pipe(catchError(this.error));
  }

  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  saveSBIColumnsInfo(layoutId: string, tableKey: string, value: any) {
    const endpoint = this.appConfigService.getBackendPath('toggleLayout') + '/' + layoutId + '/' + tableKey;
    return this.httpClient.post<any>(endpoint, value).pipe(catchError(this.error));
  }

  getSearchResults(value: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const endpoint = `${this.appConfigService.getPortalNgPath()}` + '/' + 'searchsvc/dosearch';
    return this.httpClient.post<any>(endpoint, value, httpOptions);
  }

  getDataFromProxyServic(value: any) {
    const endpoint = `${this.appConfigService.getPortalNgPath()}` + '/' + 'proxyRequest';
    return this.httpClient.post<any>(endpoint, value).pipe(catchError(this.error));
  }

  getDataFromProxyServicDownload(payload: any): Observable<any> {
    const url = `${this.appConfigService.getPortalNgPath()}` + '/' + 'proxyRequest';
    return this.httpClient.post(url, payload,
      {
        responseType: 'text',
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      });
  }

  setSortFieldOrder(layoutId: any, field: any, value: any) {
    const endpoint = `${this.appConfigService.getPortalNgPath()}` + '/users/userWidgetLayoutData/' + layoutId + '/' + field;
    return this.httpClient.post<any>(endpoint, value).pipe(catchError(this.error));
  }

  setSortFieldOrderById(layoutId: any, field: any, value: any) {
    const endpoint = `${this.appConfigService.getPortalNgPath()}` + '/users/userWidgetLayoutDataById/' + layoutId + '/' + field;
    return this.httpClient.post<any>(endpoint, value).pipe(catchError(this.error));
  }

  updateHotParts(objInput: any) {
    const endpoint = `${this.appConfigService.getPortalNgPath()}` + '/hotPartsList/saveOrUpdateFile';
    return this.httpClient.post<any>(endpoint, objInput).pipe(catchError(this.error));
  }

  getLayoutContent(layoutId: string) {
    const endpoint = `${this.appConfigService.getPortalNgPath()}` + '/users/userWidgetLayoutData/' + layoutId;
    return this.httpClient.get(endpoint).pipe(catchError(this.error));
  }

  getPartStatus(ccid: string, partNos: string) {
    let endpoint = this.appConfigService.getBackendPath('getPartStatusEndpoint');
    endpoint = endpoint.replace('{ccid}', ccid);
    return this.httpClient.post<any>(endpoint, partNos).pipe(delay(1000), this.handleRetryError(2000));
  }

  getOrderStatus(ccid: string) {
    let endpoint = this.appConfigService.getBackendPath('getOrderStatusEndpoint');
    endpoint = endpoint.replace('{ccid}', ccid);
    return this.httpClient.get<any>(endpoint).pipe(delay(1000), this.handleRetryError(2000));
  }

  getConstructWidget(constructWidgetOptions: ConstructWidgetOptions): CompleteWidgetdata {
    const widgetData: any = {};
    const containerData: any = {};
    const completeWidget: any = {};
    widgetData.url = constructWidgetOptions.url;
    widgetData.shortName = constructWidgetOptions.shortName;
    widgetData.longName = constructWidgetOptions.longName;
    widgetData.description = constructWidgetOptions.description;

    if (constructWidgetOptions.id) {
      widgetData.id = constructWidgetOptions.id;
    }
    if (constructWidgetOptions.searchQuery) {
      widgetData.searchQuery = constructWidgetOptions.searchQuery;
    }
    if (constructWidgetOptions.customIcon) {
      widgetData.icon = constructWidgetOptions.customIcon;
    }
    if (constructWidgetOptions.customFormat) {
      widgetData.customFormat = constructWidgetOptions.customFormat;
    }
    if (constructWidgetOptions.templateName && constructWidgetOptions.templateList) {
      for (const elem of constructWidgetOptions.templateList) {
        if (elem.implClassName === constructWidgetOptions.templateName) {
          widgetData.templateId = elem.templateId;
          widgetData.templateName = elem.implClassName;
        }
      }
    }
    if (constructWidgetOptions.folderId) {
      containerData.containerId = constructWidgetOptions.folderId;
    } else if (constructWidgetOptions.parentId) {
      containerData.containerId = constructWidgetOptions.parentId;
    }
    completeWidget.containerData = containerData;
    completeWidget.widgetData = widgetData;
    return completeWidget;
  }

  handleRetryError(delayTime) {
    let retries = 0;
    const exceedAttemptLimit = 3;
    return retryWhen((error) => {
      return error.pipe(
        delay(delayTime),
        concatMap((err) => {
          retries = retries + 1;
          if (retries < exceedAttemptLimit) {
            return of(err);
          } else {
            throw err;
          }
        })
      );
    });
  }

}

