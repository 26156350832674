<div *ngIf="!viewSearchBar || searchDisplayBar" #insideClick class="search-filed-wrapper no-elevation inline-block" [formGroup]="headerGroup" [ngClass]="{'w-full':searchDisplayBar==true}" (click)=" this.enableSearchClick(true);">
    <mat-toolbar class="search-block search-height" (click)="enableSearchClick(true)" [ngClass]="[(hasFocus==true || searchDisplayBar==true || clearSearchText==true)?'search-has-focus':'search-has-no-focus',(searchDisplayBar==true)?'mat-elevation-z0':'mat-elevation-z4']">
        <mat-toolbar-row id="global-search-target" class="text-px-16 search-min-width p-0" *ngIf="!searchDisplayBar">
            <div id="mat-cat-large" class="alt text-px-14" [ngClass]="(hasFocus==true || searchDisplayBar==true || clearSearchText==true)?'category-background':'search-cat-background'">
                <mat-form-field class="search-drop-field">
                    <mat-select #lgMatSelect="matSelect" class="search-drop-down" disableOptionCentering formControlName="selectedCat" (selectionChange)="onCatChange($event)" panelClass="mat-cat-dropdown display_large_screen_cat">
                        <div #insideClick>
                            <mat-option value="">{{'All'}}</mat-option>
                            <mat-option *ngFor="let category of searchCategories | categoryListByUserAccess:user" value="{{category.id}}">
                                <span>{{category.label}}</span>
                            </mat-option>
                        </div>
                    </mat-select>

                </mat-form-field>
            </div>

            <span class="search-bar flex w-full" [ngClass]="{'search-bar-focus':hasFocus==true}">
                <div class="inline-block w-0" #searchMenu #menuTrigger="matMenuTrigger"
                    (menuOpened)="advFilterMenuOpened()" [matMenuTriggerFor]="advsrchFilter">&nbsp;</div>
                <span *ngIf="!searchDisplayBar" class="search-click mt-3 w-30px">
                    <i class="zmdi zmdi-search search-click text-px-24"></i>
                </span>
            <input matInput #searchInput maxLength="80" formControlName="searchText" autocomplete="off" id="searchInput" (keypress)="hasFocus=true;menuTrigger.openMenu();enableSearchIcons();" (keyup.backspace)="hasFocus=true;menuTrigger.openMenu();enableSearchIcons();"
                (paste)="searchClickFunc($event)" (click)="advsearchFilter=false" (keyup.enter)="goToSearchPage(null)" class="search-control search-input-padding" type="text" placeholder="Search">
            <button matTooltip="Clear search" *ngIf="!searchDisplayBar && showSearchClearIcons" (click)="disableClearSearchIcons();" class="search-highlight-hover w-42px" mat-icon-button>
                    <i class="zmdi zmdi-close search-click text-px-24" 
                    ></i>
                    
                 
                </button>
            <button *ngIf="!searchDisplayBar" (click)="goToSearchPage(null)" class="search-click w-42px" mat-icon-button>
                    <i class="zmdi zmdi-arrow-right search-click text-px-24"></i>
                </button>
            </span>
        </mat-toolbar-row>

        <mat-toolbar-row id="matsearchbar" class="text-px-12 p-0" *ngIf="searchDisplayBar">
            <div id="mat-drop-id" class="alt text-px-12 alt-sm" [ngClass]="{'category-background':(hasFocus==true || searchDisplayBar==true)}">
                <mat-form-field class="search-drop-field">
                    <mat-select #smMatSelect="matSelect" class="search-drop-down-sm search-drop-down" disableOptionCentering formControlName="selectedCat" (selectionChange)="onCatChange($event)" panelClass="mat-cat-dropdown display_small_screen_cat">
                        <div #insideClick>
                            <mat-option value="">{{'All'}}</mat-option>
                            <mat-option *ngFor="let category of searchCategories | categoryListByUserAccess:user" value="{{category.id}}">
                                <span>{{category.label}}</span>
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="flex w-full search-small-ph">
                <div class="inline-block w-0" #searchMenu #menuTrigger="matMenuTrigger" (menuOpened)="advFilterMenuOpened()" [matMenuTriggerFor]="advsrchFilter">&nbsp;</div>
                <i class="zmdi zmdi-search pl-2 pt-4 search-icon-small pr-2"></i>
                <input matInput #searchInput maxLength="80" formControlName="searchText" autocomplete="off" id="searchInputSm" (keyup.backspace)="hasFocus=true;menuTrigger.openMenu()" (paste)="searchClickFunc($event)" (keypress)="hasFocus=true;menuTrigger.openMenu()"
                    (click)="advsearchFilter=false" (keyup.enter)="goToSearchPage(null)" class="search-control text-pix-13" type="text" placeholder="Search">
                <button class="search-icon-margin pr-8" mat-icon-button (click)="closeSearchBar()" [autofocus]="false" [disableRipple]="true">
                <i class="zmdi zmdi-close zmdi-close-style" ></i>
            </button>
            </div>
        </mat-toolbar-row>

    </mat-toolbar>
    <mat-menu #advsrchFilter="matMenu" xPosition="after" yPosition="below" [hasBackdrop]="false" [overlapTrigger]="false" class="search-dropdown search-drop-max-width" [ngStyle]="{'width':getWidth()}">
        <ng-container *ngIf="!advsearchFilter" #insideClick>
            <ng-container *ngIf="isLoading">
                <mat-option class="line-height-reset search-option-padding">
                    <div>
                        <div class="pr-3">
                            <span>
                                <span>
                                    Loading...
                                </span>
                            <mat-spinner class="float-right leading-none" diameter="25"></mat-spinner>
                            </span>
                        </div>
                    </div>
                </mat-option>
            </ng-container>

            <ng-container *ngIf="!isLoading">
                <mat-option *ngFor="let searchResult of searchResults;let i = index" [value]="headerGroup.controls['searchText'].value" (mouseover)="highlightFunction()" class="text-px-14 search-highlight-hover search-opt-wrap line-height-reset search-option-padding"
                    (click)="goToSearchPage(searchResult)" [ngClass]="{'highlight-first-option':i==0 && highlightValue == false}">
                    <div class="search-opt-inner-wrap" *ngIf="searchResult.description.lastIndexOf('-') as DashLastIndex">
                        <div class="items-center">
                            <span class="search-results-title-label">{{ searchResult.ridentifier !== 'App Name Long' ? searchResult.ridentifier : 
                                (searchResult.appType === 'Application' ? 'Application' : 'Dashboard') }}</span>
                            <br *ngIf="searchResult.ridentifier">
                            <span class="with-out-open">
                                <label [innerHTML]="(DashLastIndex>0?searchResult.description.substring(0, DashLastIndex):searchResult.description)  | highlightText:headerGroup.controls['searchText'].value | safe:'html'"></label>
                                <label *ngIf="searchResult.appType==='Application'"
                                [analyticTagging]="{'eventId':'appsClickFromSearchHeader','srcCtrl':searchResult}"
                                class="uppercase font-semibold with-open"
                                (click)="$event.stopPropagation();goToApplicationPage(searchResult);"> &nbsp; &nbsp;Open
                                </label>
                            </span>
                        </div>
                    </div>
                </mat-option>
            </ng-container>
        </ng-container>
    </mat-menu>
</div>