import { Component, Inject, NgZone, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConfigService } from 'src/app/core/app-config.service';
import tutorialData from '../../../assets/config/data/tutorial/applist-tutorial-MBF.json';

@Component({
  selector: 'app-applist-tutorial-dialog',
  templateUrl: './applist-tutorial-dialog.component.html',
  styleUrls: ['./applist-tutorial-dialog.component.css']
})
export class ApplistTutorialDialogComponent implements OnInit {
  action = '';
  dialogData: any = [];
  isLinear = false;
  tutorialSlides: any = [];
  currentIndex = 0;
  layoutName = 'welcome';
  tHiLight: any;
  tHiTarget: any;
  plateformName = '';
  currentSlide: any;
  closeSts = false;
  loaded = false;
  fullBodyHgt = 0;

  constructor(
    public dialogRef: MatDialogRef<ApplistTutorialDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private appConfigService: AppConfigService,
    private ngZone: NgZone) {
    this.action = data.action;
    this.dialogData = data.data;
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.appConfigService.envConfig.subscribe(config => {
      this.plateformName = config[`oemPlatformName`];
    });
    this.currentIndex = 0;
    this.tutorialSlides = tutorialData;
    this.tHiLight = document.getElementById('tutorial-highlight');
    this.tHiLight.style.display = 'block';
    this.tHiTarget = document.getElementById('tutorial-highlight-target');
    this.tHiTarget.style.height = '0px';
    this.tHiTarget.style.width = '0px';
    this.snackBar.dismiss();
    document.getElementById('htmlContent').style.overflow = 'hidden';
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('resize', this.onResize.bind(this));
    });
    this.closeSts = true;
    this.fullBodyHgt = document.getElementById('fullBodyContent').offsetHeight;
  }

  next() {
    window.scrollTo(0, 0);
    this.tHiTarget.style.height = '0px';
    this.tHiTarget.style.width = '0px';
    const cnt = document.getElementById('tutorial-dlg');
    if (this.currentIndex < this.tutorialSlides.length) {
      this.currentIndex += 1;
      if (window.innerWidth > 991) {
        setTimeout(() => this.highlightingElement(this.tutorialSlides[this.currentIndex], cnt), 300);
      }
      this.currentSlide = this.tutorialSlides[this.currentIndex];
    }
    this.layoutName = this.tutorialSlides[this.currentIndex].mediaPresent ? 'contentWithMedia' : 'content';
  }

  highlightingElement(item, tmodel) {
    this.ngZone.runOutsideAngular(() => {
      const hElement = document.getElementById('tutorial-highlight');
      const hElementTarget = document.getElementById('tutorial-highlight-target');
      if (window.innerWidth > 991) {
        if (item && item.highlight && document.getElementById(item.highlight) || item.index === 1) {
          const marker = document.getElementById(item.highlight).getBoundingClientRect();
          if (!(item.index === 4 || item.index === 6 || item.index === 1 || item.index === 2 || item.index === 3 || item.index === 5)) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            hElement.style.display = 'block';
            hElementTarget.style.top = (marker.top) + 'px';
            hElementTarget.style.left = (marker.left) + 'px';
            hElementTarget.style.height = (marker.height) + 'px';
            hElementTarget.style.width = (marker.width) + 'px';
            if (item.position === 'left') {
              tmodel.style.left = (marker.left - 5) + 'px';
              tmodel.style.top = (marker.top + marker.height + 24) + 'px';
            } else if (item.position === 'right') {
              tmodel.style.left = 'calc(' + (marker.right + 5) + 'px - 360px)';
              tmodel.style.top = (marker.top + marker.height + 24) + 'px';
            }
          } else {
            const browser = window.navigator.userAgent;
            const isIE = browser.indexOf('MSIE ') > -1 || browser.indexOf('Trident/') > -1;
            let nb = 0;
            nb = parseInt((marker.top + '').replace('px', ''), 10) - 24;
            if (isIE) {
              window.scrollTo(0, nb);
            } else {
              window.scrollTo({ top: nb, behavior: 'smooth' });
            }
            let adjustedPadding = 0;
            if (item.index === 2 || item.index === 3) {
              adjustedPadding = 5;
            }
            if (marker) {
              hElement.style.display = 'block';
              hElementTarget.style.top = (marker.top - nb - adjustedPadding) + 'px';
              hElementTarget.style.left = (marker.left - adjustedPadding) + 'px';
              hElementTarget.style.height = (marker.height + 2 * adjustedPadding) + 'px';
              hElementTarget.style.width = (marker.width + 2 * adjustedPadding) + 'px';
            } else {
              hElementTarget.style.height = '0px';
              hElementTarget.style.width = '0px';
            }
            if (item.position === 'left') {
              tmodel.style.left = (marker.left - adjustedPadding) + 'px';
              tmodel.style.top = (marker.height + 48) + 'px';
            } else if (item.position === 'right') {
              tmodel.style.left = 'calc(' + (marker.right + 5) + 'px - 360px)';
              tmodel.style.top = (marker.top + marker.height + 24 - nb) + 'px';
            } else {
              tmodel.style.left = 'calc(50% - 180px)';
              tmodel.style.top = 'calc(50% - 180px)';
            }
          }
        } else {
          tmodel.style.left = 'calc(50% - 180px)';
          tmodel.style.top = 'calc(50% - 265px)';
        }
      } else {
        tmodel.style.left = 'calc(50% - 180px)';
        tmodel.style.top = 'calc(50% - 180px)';
      }
    });
  }

  closeDialog() {
    this.closeSts = false;
    this.tHiLight.style.display = 'none';
    document.getElementById('htmlContent').style.overflow = 'auto';
    this.dialogRef.close();
  }

  onResize(event) {
    const tdialog = document.getElementById('tutorial-dlg');
    if (this.closeSts) {
      this.highlightingElement(this.tutorialSlides[this.currentIndex], tdialog);
    }
  }

}
