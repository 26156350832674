<mat-sidenav-container (backdropClick)="closeFunc()" [hasBackdrop]="backDropFun()">
    <mat-sidenav *ngIf="!isSolo && isMobile && isOtherPage && !isClafSupport" [(opened)]="opened" #sidenav disableClose [fixedInViewport]="true" class="sidenav-width">
        <mat-nav-list>
            <ng-container *ngIf="isMobile">
                <app-header-mbf-sidenav></app-header-mbf-sidenav>
            </ng-container>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <div id="fullBodyContent" ng-class="relative min-h-994">
            <div scroll width="992" widthDefault="true" offset="70" class="min-h-calc">
                <ng-container *ngIf="!isSolo && !isClafSupport">
                    <header *ngIf=isOtherPage class="relative z-5">
                        <app-header-mbf></app-header-mbf>
                    </header>
                </ng-container>
                <div class="mx-auto w-full">
                    <router-outlet (activate)="changeOfRoutes()"></router-outlet>
                </div>
            </div>
            <div *ngIf='isGdprBannerAvailable && isOtherPage'>
                <app-cookie-banner [isFeatureActive]='isGdprBannerAvailable'></app-cookie-banner>
            </div>
            <app-back-to-top *ngIf="!isSolo && !isClafSupport"></app-back-to-top>
            <footer *ngIf="!isSolo && isOtherPage && !isClafSupport" class="pt-96px">
                <app-footer-mbf></app-footer-mbf>
            </footer>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>