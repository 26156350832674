import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { AppConfigService } from 'src/app/core/app-config.service';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppListingService {

  // tslint:disable-next-line: variable-name
  private readonly cache_api: Map<string, Observable<string[]>> =
    new Map<string, Observable<string[]>>();
    isSrcPublish = new BehaviorSubject<boolean>(false);
    isSrcPublishObservable = this.isSrcPublish.asObservable();
  constructor(private httpClient: HttpClient,
              private appConfigService: AppConfigService,
              ) { }

  // Get app lists
  public getAppList(applists: any = [], withCache: boolean = true, updateCache: boolean = false, src?: any): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('appLists');
    if (src === 'publish'){
          updateCache = true;
    }
    return this.getXHRCall(endpoint, withCache, applists, updateCache);
  }
  public getAppList1(applists: any = [], withCache: boolean = true, updateCache: boolean = false, src?: any): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('appLists');
    if (src === 'publish'){
      this.setisSrcPublish(true);
    }
    return this.httpClient.get<any>(endpoint).pipe(catchError(this.error));
  }

  // Get most accessed app lists
  public getuserAppList(userAppList: any = [], withCache: boolean = true, updateCache: boolean = false, src?: any): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('userAppList');
    if (src === 'publish'){
          updateCache = true;
    }
    return this.getXHRCall(endpoint, withCache, userAppList, updateCache);
  }

  public getuserAppList1(userAppList: any = [], src?: any): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('userAppList');
    if (src === 'publish'){
      this.setisSrcPublish(true);
    }
    return this.httpClient.get<any>(endpoint).pipe(catchError(this.error));
  }
  public getAppListRefresh(): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('appLists');
    return this.httpClient.get(endpoint).pipe(catchError(this.error));
  }

  private getXHRCall(endpoint, withCache, applists, updateCache): Observable<any> {
    if (withCache){
      if (!this.cache_api[endpoint] || updateCache) {
        this.cache_api[endpoint] = this.httpClient.get<any>(endpoint).pipe(shareReplay(), catchError(this.error));
      }
    } else {
      this.cache_api[endpoint] = of(applists);
    }
    return this.cache_api[endpoint];
  }

  public updatedAppList(widget, status) {
    const endpoint = this.appConfigService.getBackendPath('appLists');
    if (this.cache_api[endpoint]){
      this.cache_api[endpoint].subscribe((response: any) => {
        if (response && response.data){
          const obj = response.data.findIndex(x => widget.longName === x.longName && widget.id === x.id);
          if (obj){
            if (status === 'add'){
              if (response.data[obj]){
                response.data[obj].addedTo = [widget];
              }
            }
            if (status === 'remove'){
              if (response.data[obj]){
                delete response.data[obj].addedTo;
              }

            }
          }
          this.cache_api[endpoint] = of(response);
        }
      });
    }
  }

  // Handle Errors
  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  public addToFavorites(widgetId: string, workspaceId: string): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('widgetsEndPoint') + '/' + workspaceId + '/layouts?widgetIds=' + widgetId;
    return this.httpClient.get<any>(`${endpoint}`, { observe: 'response' }).pipe(catchError(this.error));
  }
  removeWidget(widgetId: string, workspace: string): Observable<any> {
    const url = `${this.appConfigService.getBackendPath()}/widgets/${widgetId}/layouts/${workspace}/removefromapp`;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json;charset=UTF-8' }) };
    return this.httpClient.post(url, httpOptions).pipe(catchError(this.error));
  }
  setisSrcPublish(value: boolean): void {
    this.isSrcPublish.next(value);
}

}
