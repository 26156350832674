import { Injectable } from '@angular/core';
import { Adapter } from 'src/app/core/interfaces/adapter';
import { User } from 'src/app/user/user';
import { UserRolesItem } from 'src/app/user/user-roles-item';
import { UserRoleArrayAdapter } from 'src/app/user/user-role-adapter';
import { SearchAccessListItem } from 'src/app/search/search-access-list-item';



@Injectable({
    providedIn: 'root'
})
export class UserAdapter implements Adapter<User> {
    constructor(private userRoleArrayAdapter: UserRoleArrayAdapter) {}
    adapt(item: any): User {
        if (item == null) { return null; }
        return {
            userId: item.userId || '',
            bemsId: item.bemsId || '',
            wssoBemsId: item.wssoBemsId || '',
            firstName: item.firstName || '',
            lastName: item.lastName || '',
            middleName: item.middleName || '',
            fullName: item.fullName || '',
            airlineCode: item.airlineCode || '',
            emailAddress: this.fixEmail(item.emailAddress),
            country: item.country || '',
            preferences: item.preferences || '',
            userRoles: this.userRoleArrayAdapter.adapt(item.userRoles) || new Array<UserRolesItem>(),
            searchAccessList: item.searchAccessList || new Array<SearchAccessListItem>(),
            initialLogin: item.initialLogin || 0,
            latestLogin: item.latestLogin || 0,
            userDataSources: item.userDataSources || new Array<string>(),
            externalUser: item.externalUser || false,
            phone: item.phone || ''
        };
    }

    private fixEmail(email: string): string   {
        return email
            ? email.replace('@exchange.boeing.com', '@boeing.com')
            : '';
    }
}
