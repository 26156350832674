<div class="display_side_Menu">
    <div class="ml-6 mt-1 outline-none flex pb-4">
        <a [href]="envConfig['oemUrl']" target="_blank" class="pt-4 outline-0 " (click)="closeSideMenu()">
            <img src="assets/img/logos/boeing_logo_black.png" class="w-56" [analyticTagging]="{'eventId':'globalNavLogoFn','srcCtrl':envConfig['oemName']}">
        </a>
        <div class="justify-end w-full flex pr-2">
            <button #linkTrigger="matMenuTrigger" [analyticTagging]="{'eventId':'globalNavIceCubeTrayFn','srcCtrl':'Ice Cube Tray'}" class="cursor-pointer" mat-button matTooltip="Links" (click)="closeAccountMenu()" #linkMenuId="matMenuTrigger" [ngClass]="{'highlight-account-border':linkMenuId.menuOpen}"
                [matMenuTriggerFor]="linkMenu">
                <i class="zmdi zmdi-apps text-5xli"></i>
            </button>
            <button #accountTrigger="matMenuTrigger" [analyticTagging]="{'eventId':'globalNavAccountFn','srcCtrl':'Account'}" class="cursor-pointer" mat-button matTooltip="Account" (click)="closeLinkMenu()" [matMenuTriggerFor]="accountMenu" #accountMenuId="matMenuTrigger"
                [ngClass]="{'highlight-account-border':accountMenuId.menuOpen}">
                <i class="zmdi zmdi-account-circle text-5xli"></i>
            </button>
        </div>
    </div>
    <div *ngIf="!displayMenuOptions">
        <mat-divider></mat-divider>
        <div class="flex pl-10 items-center pt-4 pb-4">
            <div>
                <span class="header-side-menu-label">
                    {{envConfig['oemPlatformName']}}
                </span>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="py-4">
            <div *ngFor="let item of secondaryBannerLeft" class="justify-between h-full items-center">
                <div *ngIf="item | headerAdminLink:user:envConfig" [ngClass]="item.title === currentTab.toUpperCase() ? 'selected-menu-shadow':'hover-background'" class="secnd-banner-applications-{{envConfig['oemPlatformAcronym']}} mx-2 py-4">
                    <span class="header-side-menu-subtitle pl-8"><a *ngIf="IfAdminLink(item)" (click)="closeSideMenu()"
                            [href]="memoizeGetBuildUrl | headerBuildUrl:item"
                            [ngClass]="{'font-bold': item.title == currentTab }"
                            [analyticTagging]="{'eventId':item.analytics?item.analytics.eventId:'','srcCtrl':item.analytics?item.analytics.srcCtrl:''}"
                            target="{{item.target}}">{{item.title | customCamelCase }}</a>
                    </span>
                </div>
            </div>
        </div>
        <mat-divider class="pb-4"></mat-divider>
        <div class="pt-4 pb-4 mx-2 hover-background mouse-pointer" *ngIf="this.currentTab == 'MBF Home'">
            <a [analyticTagging]="{'eventId':'secondaryBannerIconsFn','srcCtrl':'Workspace'}" class="ml-8 flex items-center header-side-menu-subtitle" (click)="actionOptions(true)">
                <mat-icon>view_quilt</mat-icon>
                <span class="header-side-menu-subtitle pl-4">Actions</span>
            </a>
        </div>
        <div class="pt-4 mx-2 pb-4 hover-background mouse-pointer">
            <a [analyticTagging]="{'eventId':'secondaryBannerNotificationIconFn','srcCtrl':this}" class="ml-8 flex items-center" (click)="notificationOptions(true)" [ngClass]="{'pointer-events-none': undismissedNotificationsAvailable}">
                <mat-icon [matBadge]=unreadNotifications matBadgeColor="warn" matBadgeSize="small" [matBadgeHidden]="!unreadNotificationsAvailable">notifications</mat-icon>
                <span class="header-side-menu-subtitle pl-4">
                    Notifications
                </span>
            </a>
        </div>
        <div class="pt-4 pb-4 mx-2 hover-background mouse-pointer">
            <a [analyticTagging]="{'eventId':'secondaryBannerIconsFn','srcCtrl':'Help'}" class="ml-8 flex items-center header-side-menu-subtitle" (click)="displayHelp(true)">
                <mat-icon class="pr-4">help</mat-icon>
                <span class="header-side-menu-subtitle pl-4">Help </span>
            </a>
        </div>
        <div class="mt-4">
            <mat-divider></mat-divider>
        </div>
    </div>
    <div *ngIf="displayHelpOptions">
        <mat-divider></mat-divider>
        <div class="flex py-2 items-center">
            <button mat-button (click)="displayHelp(false)">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="header-side-menu-label">Help</div>
        </div>
        <mat-divider></mat-divider>
        <div class="ml-4 pt-4">
            <div *ngFor="let item of helpItems">
                <span *ngIf="item.title=='My Field Service Office';then MyFielderviceLink else otherLinks">
                </span>
                <ng-template #MyFielderviceLink>
                    <a *ngIf="!(user && user.airlineCode && user.airlineCode == 'TBC')" [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}" mat-menu-item class="ml-2 header-side-menu-subtitle" [href]="buildUrl(item)" (click)="closeSideMenu()"
                        [target]="item.target">{{item.title}}</a>
                </ng-template>
                <ng-template #otherLinks>
                    <span *ngIf="item.title=='Access Administrator Support';then accessAdminLink else noAccessAdminLinks"></span>
                    <ng-template #accessAdminLink>
                        <a *ngIf="accessAdministratorFlag" [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}" mat-menu-item class="ml-2 header-side-menu-subtitle" [href]="buildUrl(item)" (click)="closeSideMenu()" [target]="item.target">{{item.title}}
                    </a>
                    </ng-template>
                    <ng-template #noAccessAdminLinks>
                            <a *ngIf="item.title === 'Product and Data Access (Access Summary)'" [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}" mat-menu-item class="ml-2 header-side-menu-subtitle" [href]="memoizeGetBuildUrl | headerBuildUrl:item" (click)="closeSideMenu()"
                            [target]="item.target">{{item.title}}
                            </a>
                            <a *ngIf="item.title != 'Tutorial' && item.title != 'Product and Data Access (Access Summary)'" [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}" mat-menu-item class="ml-2 header-side-menu-subtitle" [href]="memoizeGetBuildUrl | headerBuildUrl:item" (click)="closeSideMenu()"
                                [target]="item.target">{{item.title}}
                            </a>
                            <a *ngIf="item.title == 'Tutorial'" [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}" mat-menu-item class="ml-2 header-side-menu-subtitle" (click)="openTutorialDialog('Tutorial')">
                                {{item.title}}
                            </a>
                    </ng-template>
                </ng-template>
            </div>
        </div>
    </div>
    <mat-menu class="link-menu" #linkMenu="matMenu" [hasBackdrop]="false">
        <div class="ml-6 header-side-menu-subtitle pt-4" *ngFor="let menu of secondaryMenu; let first = first">
            <div class="{{first ? '' : 'pt-2'}}"></div>
            <div class="header-menu-title pb-1 text-px-14">{{menu.title}}</div>
            <ng-container *ngFor="let submenuItem of menu.submenu">
                <a (click)="closeSideMenu()" [analyticTagging]="{'eventId':'globalNavTrayDropDownExitLinkFn','srcCtrl':[menu.title,submenuItem.title]}" *ngIf="buildUrl(submenuItem) else notLink; let url" mat-menu-item class="{{submenuItem.title === 'MyBoeingFleet' && mbfHome ? 'text-gray-500' : ''}}"
                    href="{{url}}" [target]="submenuItem.target">{{submenuItem.title}}
                </a>
                <ng-template #notLink>
                    <span mat-menu-item disabled class="text-gray-500">
                        {{submenuItem.title}}
                    </span>
                </ng-template>
            </ng-container>
        </div>
    </mat-menu>

    <mat-menu class="account-menu" #accountMenu="matMenu" [hasBackdrop]="false">
        <div class="pt-4 pl-2 mb-8 truncate text-left max-w-full relative header-side-menu-subtitle">
            <div class="flex-row flex-wrap pb-4 pl-8">
                <div class="text-2xl font-bold text-account-header h-12 truncate">{{profileMenuMessage}}</div>
                <div class="text-xl font-bold text-gray-700 pt-2 truncate">{{profileEmailAddress}}</div>
            </div>
            <a (click)="closeSideMenu()" [ngClass]="{'selected-menu-shadow': ('My Account Settings' == currentTab) }" mat-menu-item href={{yourAccountLink}} target="_blank" class="mr-2 text-2xl h-16i text-submenu c-black  pl-0" [analyticTagging]="{'eventId':'globalNavAccountDropDownFn','srcCtrl':'Your Account'}">
                <span class="pl-8" [ngClass]="{'font-bold': ('My Account Settings' == currentTab) }"> My Account Settings</span>
            </a>
        </div>
        <a mat-menu-item disabled class="filler"></a>
        <a (click)="closeSideMenu()" [analyticTagging]="{'eventId':'globalNavAccountDropDownFn','srcCtrl':'LOG OUT'}" mat-menu-item (click)="openLogoutModal()" class="-mb-3 text-2xl ml-8 logout-link uppercase">
            Log out
        </a>

    </mat-menu>
    <div *ngIf="displayNotificationOptions">
        <mat-divider></mat-divider>
        <div class="flex py-2 items-center">
            <button mat-button (click)="notificationOptions(false)">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="header-side-menu-label">Notifications</div>
        </div>
        <mat-divider></mat-divider>
        <div class="float-right flex items-end pr-1  w-full">
            <div class="text-right w-full">
                <div class="pt-1"></div>
                <div>
                    <div class="notificationLine" mat-menu-item *ngFor="let item of notifications">
                        <div class="notification-message-width float-left" (click)="openNotificationDetailsDialog('Details', item)" [analyticTagging]="{'eventId':'notificationFlyout','srcCtrl':this}">
                            <div class="notificationHeading text-base" [ngClass]="{'read-Heading': item.messageRead }">
                                <span class="dot" *ngIf="!item.messageRead" [ngClass]="{
                                        'unplanned-dot' : item.messageType === 'UNPLANNED_OUTAGE',
                                        'planned-dot' : item.messageType === 'PLANNED_OUTAGE',
                                        'update-dot' : (item.messageType === 'USER_NOTIFICATION' || item.messageType === 'INFORMATIONAL')
                                        }"></span> {{memoizeGetmessageType | getNotificationHeader:item.messageType }}
                            </div>
                            <div class="notificationTitle" [ngClass]="{'read-Title': item.messageRead }">
                                {{ item.messageTitle }}
                            </div>
                        </div>
                        <div>
                            <button *ngIf="item.messageType === 'USER_NOTIFICATION'" mat-button matTooltip="Click to delete the notification" (click)="deleteNotification(item)">
                                <mat-icon class="close-icon-notification">close</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="no-notification" *ngIf="notifications.length === 0">
                        <span class="item.messageRead">No active notifications or alerts.</span>
                    </div>
                </div>
                <div *ngIf="unreadMessages.length > 0" class="clearUpdates" (click)="clearUpdates()">
                    MARK ALL AS READ
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="displayActionOptions">
        <mat-divider></mat-divider>
        <div class="flex py-2 items-center">
            <button mat-button (click)="actionOptions(false)">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="header-side-menu-label">Actions</div>
        </div>
        <mat-divider></mat-divider>
        <div class="ml-6 header-side-menu-subtitle pt-4">
            <span class="tracking-wider header-menu-title text-px-14">MBF HOME</span>
            <div class="ml-6 header-side-menu-subtitle pt-4"></div>
            <ng-container *ngIf="!workspaceLocked">
                <a mat-menu-item class="text-color-black" *ngFor="let item of dashboardItems" (click)="openDashboardDialog(item)" [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}">{{item.title}}
                </a>
            </ng-container>
            <ng-container *ngIf="workspaceLocked">
                <div *ngFor="let item of dashboardItems" matTooltipPosition="above" [matTooltipDisabled]="item.title == 'Reset home page to default'" matTooltip="Workspace does not support function. Please change your workspace and try again.">
                    <a mat-menu-item class="text-color-black" [ngClass]="{'dashboard-disable':(item.title != 'Reset home page to default')}" (click)="(item.title == 'Reset home page to default') && openDashboardDialog(item)" [disabled]="item.title != 'Reset home page to default'"
                        [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}">{{item.title}}
                    </a>
                </div>
            </ng-container>
            <div *ngIf="communitiesItems.length > 0">
                <div class="pt-8"></div>
                <span class="tracking-wider header-menu-title text-px-14">COMMUNITIES</span>
                <div class="pt-1"></div>
                <a mat-menu-item class="text-color-black" *ngFor="let item of communitiesItems" href="{{item.url}}" [target]="item.urlTarget" [analyticTagging]="{'eventId':'secondaryNavDropDownClickFn','srcCtrl':['WORKSPACE',item.shortName]}">{{item.title}}</a>
            </div>
        </div>
    </div>
    <div>
    </div>
</div>