import { ChangeDetectorRef, Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/core/app-config.service';
import { BrowserEolService } from 'src/app/services/browser-eol/browser-eol.service';
import { UserApiService } from 'src/app/user/user-api.service';
import { User } from 'src/app/user/user';
import { UserRolesItem } from 'src/app/user/user-roles-item';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

declare global {
  interface Window { s_brwsr: any; }
}

@Component({
  selector: 'app-browser-eol-dialog',
  templateUrl: './browser-eol-dialog.component.html',
  styleUrls: ['./browser-eol-dialog.component.css']
})
export class BrowserEolDialogComponent implements OnInit {

  disableContinue = true;
  envConfig = {};
  pspUrl: any;
  browserType: string;
  selectedBrowser: string;
  ieMbfBrowserOverviewUrl: string;
  ieMbfSupportedBrowsersUrl: string;
  edgeTurnOffIEModeUrl: string;
  prtlWelcomeMsgPrefixNew: string;
  prtlWelcomeMsgPrefix: string;
  prtlWelcomeMsgSuffix: string;
  adobeSelection: string;
  user = {
    firstName: '',
    lastName: '',
    userRoles: [] as UserRolesItem[]
  } as User;
  apiUrlContentLibraryEndpoint: string = this.appConfigService.getBackendPath('contentLibraryEndpoint');

  constructor(public appConfigService: AppConfigService, private router: Router, private cd: ChangeDetectorRef,
              public dialogRef: MatDialogRef<BrowserEolDialogComponent>, private browserEolService: BrowserEolService,
              private zone: NgZone,
              public snackBar: MatSnackBar,
              public userApiService: UserApiService,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.randomize();
    this.getBrowserCookiesAndBrowserType();
    this.getURLForPSP();
    this.getURLForDownload();
  }

  randomize(){
    const ie11 = document.getElementById('ie11');
    const edge = document.getElementsByClassName('edge');
    const order = Math.floor(Math.random() * 10) > 5;

    if (order){
      edge[0].insertAdjacentElement('afterend', ie11);
    } else {
      ie11.insertAdjacentElement('afterend', edge[0]);
    }
  }

  getBrowserCookiesAndBrowserType() {
    this.browserType = this.data.browserType !== undefined ? this.data.browserType :
      (sessionStorage.getItem('reloadPageBrowserType') !== null ? sessionStorage.getItem('reloadPageBrowserType') :
        this.data.browserType);
    sessionStorage.setItem('reloadPageBrowserType', this.browserType);
    this.setDialogPosition();
  }

  setDialogPosition() {
    if (this.browserType === 'Edge') {
      this.dialogRef.updatePosition({
        top: `0px`,
        right: `30px`
      });
    }
  }

  getURLForPSP(): void {
    const oemPlatformAcronym = 'oemPlatformAcronym';
    this.appConfigService.envConfig.subscribe(config => {
      if (config[oemPlatformAcronym]) {
        this.envConfig = config;
        this.pspUrl = `#/Platform/psp/${this.envConfig['mbfPspId']}`;
      }
    });
  }

  buildUrlForPSP(mbfPspPageString): string {
    let url = '';
    const mbfPspPageId = this.envConfig[mbfPspPageString];
    switch (this.appConfigService.getProperty(`mbfSupportPageTarget`)) {
      case 'portal_ng':
        url = `index.html#/Platform/apps/${mbfPspPageId}`;
        break;
      case 'portal-ui':
      default:
        url = `#/Platform/psp/${mbfPspPageId}`;
        break;
    }
    return url;
  }

  getURLForDownload(): void {
    let environment: string;
    const environmentString = 'environment';
    this.appConfigService.envConfig.subscribe(config => {
      if (config[environmentString]) {
        environment = config[environmentString];
      }
    });
    if (environment === 'frz') {
      this.ieMbfBrowserOverviewUrl = this.appConfigService.getProperty('frzieMbfBrowserOverviewUrl');
      this.ieMbfSupportedBrowsersUrl = this.appConfigService.getProperty('frzieMbfSupportedBrowsersUrl');
      this.edgeTurnOffIEModeUrl = this.appConfigService.getProperty('frzedgeTurnOffIEModeUrl');
    } else if (environment === 'prod') {
      this.ieMbfBrowserOverviewUrl = this.appConfigService.getProperty('prodieMbfBrowserOverviewUrl');
      this.ieMbfSupportedBrowsersUrl = this.appConfigService.getProperty('prodieMbfSupportedBrowsersUrl');
      this.edgeTurnOffIEModeUrl = this.appConfigService.getProperty('prodedgeTurnOffIEModeUrl');
    } else if (environment === 'test') {
      this.ieMbfBrowserOverviewUrl = this.appConfigService.getProperty('testieMbfBrowserOverviewUrl');
      this.ieMbfSupportedBrowsersUrl = this.appConfigService.getProperty('testieMbfSupportedBrowsersUrl');
      this.edgeTurnOffIEModeUrl = this.appConfigService.getProperty('testedgeTurnOffIEModeUrl');
    }
  }

  getURL(url: string, urlTarget: string, type?: string, title?: string) {
    const value = window.location.href;
    if (value.includes(url)) {
      window.scroll(0, 0);
    } else {
      const updatedUrl = this.buildLink(url, type, title);
      window.open(updatedUrl, urlTarget);
    }
  }

  buildLink(url: string, type: string = 'external', title: string = ''): string {
    let updatedUrl;
    switch (type) {
      case 'portal_ng':
        updatedUrl = `${this.appConfigService.getPortalNgPath()}/${url}`;
        break;
      case 'portal-ui':
        updatedUrl = `${this.appConfigService.getPortalUIPath()}/index.html${url}`;
        break;
      case 'rp':
        if (url.startsWith('/') || url === '') {
          updatedUrl = url;
          // tslint:disable-next-line: no-string-literal
        } else if (this.envConfig['isThroughRp']) {
          // tslint:disable-next-line: no-string-literal
          updatedUrl = `${this.envConfig['rpAlias']}${url}`;
        } else {
          updatedUrl = `/${url}`;
        }
        break;
      case 'external':
      default:
        updatedUrl = url;
        break;
    }
    this.cd.markForCheck();
    return updatedUrl;
  }

  downloadFile(url: string) {
    this.zone.runOutsideAngular(() => {
      const requesturl = this.apiUrlContentLibraryEndpoint + '/' + url + '/content';
      const element = document.createElement('a');
      element.setAttribute('href', requesturl);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    });
  }

  browserTypeConfirmtion(evt) {
    this.disableContinue = false;
    this.selectedBrowser = evt.value;
    this.adobeSelection = this.selectedBrowser === 'Edge' ? 'Using Edge in IE 11 mode' : 'Using IE 11';
  }

  submitContinue() {
    this.browserEolService.submitBrowserIdentification(this.selectedBrowser).subscribe(response => {
      if (response) {
        localStorage.setItem('MBFSelectedBrowser', this.selectedBrowser);
        window.s_brwsr = this.selectedBrowser;

      }
      sessionStorage.setItem('sentToEOL', '1');
      this.enableWelcomeMsg();
      this.router.navigate(['/Platform/workspace/']);
      this.dialogRef.close();
    });
  }

  enableWelcomeMsg() {
    this.sendUserNotification(this.setupWelcomeMessage());
  }

  sendUserNotification(message: string): void {
    const horizontalPositionVal: MatSnackBarHorizontalPosition = 'right';
    const verticalPositionVal: MatSnackBarVerticalPosition = 'top';
    const snackBarRef = this.snackBar.open(message, '', {
      duration: 1000,
      horizontalPosition: horizontalPositionVal,
      verticalPosition: verticalPositionVal,
      panelClass: 'notification-custom-snackbar'
    });
    this.cd.markForCheck();
  }

  setupWelcomeMessage() {
    this.userApiService.getUser().subscribe((user: User) => {
      this.user = user;
    });
    let portalWelcomeMessage = this.prtlWelcomeMsgPrefixNew ? this.prtlWelcomeMsgPrefixNew : 'Welcome';
    if (this.user && !this.userApiService.isNewUser(this.user)) {
      portalWelcomeMessage = this.prtlWelcomeMsgPrefix ? this.prtlWelcomeMsgPrefix : 'Welcome back';
      if (this.user.firstName) {
        portalWelcomeMessage += ' ' + this.user.firstName;
      } else if (this.user.fullName) {
        portalWelcomeMessage += ' ' + this.user.fullName;
      }
    }
    portalWelcomeMessage += this.prtlWelcomeMsgSuffix ? this.prtlWelcomeMsgSuffix : '!';
    this.cd.markForCheck();
    return portalWelcomeMessage;
  }
}
